import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import settings from "../../config";
import Header from "../Header";

const VariantTransactions = () => {
  const [variantTransactions, setVariantTransactions] = useState([]);
  const { variantId, variantCode } = useParams();

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${settings.api}variant/transactions/code`,
        {
          variantId,
          variantCode,
        },
      );

      if (response.data.statusCode === 200) {
        setVariantTransactions(response.data.data);
      }
    })();
  }, [variantCode, variantId]);

  return (
    <div className="w-full" dir="rtl">
      <Header title="معاملات الاصناف بالكود" />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">كود الصنف</th>
            <th className="p-4">اسم الصنف</th>
            <th className="p-4">نوع الصنف</th>
            <th className="p-4">عدد القطع</th>
            <th className="p-4">مبيعات</th>
            <th className="p-4">متاح</th>
            <th className="p-4">تاريخ المعامله</th>
            <th className="p-4">الموسم</th>
            <th className="text-center w-24 p-4">تركيب</th>
            <th className="text-center w-24 p-4">تفاصيل</th>
            <th className="text-center w-24 p-4">بيع</th>
          </tr>
        </thead>
        <tbody>
          {variantTransactions.map((variantTransaction) => {
            return (
              <tr
                key={variantTransaction.fabricTransactionVariantId}
                className="odd:bg-secondaryBg"
              >
                <td className="p-4">{variantTransaction.variantCode}</td>
                <td className="p-4">{variantTransaction.variantName}</td>
                <td className="p-4">{variantTransaction.variantType}</td>
                <td className="p-4">
                  {(variantTransaction.quantity || 0).toLocaleString("en-US")}
                </td>
                <td className="p-4">
                  {(variantTransaction.sold || 0).toLocaleString("en-US")}
                </td>
                <td className="p-4">
                  {(
                    (variantTransaction.quantity || 0) -
                    (variantTransaction.sold || 0)
                  ).toLocaleString("en-US")}
                </td>
                <td className="p-4">{variantTransaction.processingDate}</td>
                <td className="p-4">{variantTransaction.seasonName}</td>
                <td className="text-center p-4">
                  <div className="flex flex-col items-center w-full cursor-pointer">
                    <div className="font-bold rounded-full w-8">
                      <Link
                        to={`/variant/operations/${variantTransaction.fabricTransactionVariantId}`}
                      >
                        👕
                      </Link>
                    </div>
                  </div>
                </td>
                <td className="text-center p-4">
                  <div className="flex flex-col items-center w-full cursor-pointer">
                    <div className="font-bold rounded-full w-8">
                      <Link
                        to={`/variant/details/${variantTransaction.fabricTransactionVariantId}`}
                      >
                        📄
                      </Link>
                    </div>
                  </div>
                </td>
                <td className="text-center p-4">
                  <div className="flex flex-col items-center w-full cursor-pointer">
                    <div className="font-bold rounded-full w-8">
                      <Link
                        to={`/variant/sell/${variantTransaction.fabricTransactionVariantId}`}
                      >
                        🚚
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default VariantTransactions;
