import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";
import Header from "../Header";
import Input from "../Input/Input";
import Select from "../Select/Select";

const FabricInstalmentForm = () => {
  const navigate = useNavigate();
  const { merchantId = "", fabricInstalmentId = null } = useParams();

  const [formData, setFormData] = useState({
    merchantId,
    amount: 0,
    discount: 0,
    instalmentDate: "",
    paymentMethod: "",
    seasonId: "",
  });

  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}seasons/`);

      if (response.data.statusCode === 200) {
        setSeasons(response.data.data);
      }
    })();
  }, []);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (fabricInstalmentId) {
      (async () => {
        const response = await axios.post(
          `${settings.api}fabric/view-instalment`,
          {
            fabricInstalmentId,
          },
        );

        setFormData(response.data.data[0] || {});
      })();
    }
  }, [fabricInstalmentId]);

  return (
    <form
      dir="rtl"
      onSubmit={async (e) => {
        e.preventDefault();

        const response =
          (formData.fabricInstalmentId &&
            (await axios.post(`${settings.api}fabric/update-instalment`, {
              ...formData,
              merchantId,
            }))) ||
          (await axios.post(`${settings.api}fabric/create-instalment`, {
            ...formData,
            merchantId,
          }));

        if (response.data.statusCode === 200) {
          navigate(`/fabric/instalments/${merchantId}`);
        }
      }}
    >
      <div className="w-full">
        <Header title="استماره دفعات القماش" />

        <div className="flex flex-col w-full lg:w-1/2 m-auto gap-4">
          <Input
            label="الدفعه"
            value={formData.amount}
            onChange={onChange}
            name="amount"
            required
          />

          <Input
            label="الخصم"
            value={formData.discount}
            onChange={onChange}
            name="discount"
          />

          <Input
            label="تاريخ الدفعه"
            value={formData.instalmentDate}
            onChange={onChange}
            name="instalmentDate"
            required
            placeholder="yyyy-mm-dd"
            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
            type="date"
          />

          <Select
            label="طريقه الدفع"
            value={formData.paymentMethod}
            onChange={onChange}
            name="paymentMethod"
            required
          >
            <option value="">اختر</option>
            <option value="نقدى">نقدى</option>
            <option value="فودافون كاش">فودافون كاش</option>
            <option value="اتصالات كاش">اتصالات كاش</option>
            <option value="بوسطه">بوسطه</option>
            <option value="بنك">بنك</option>
            <option value="شيك">شيك</option>
          </Select>

          <Select
            label="اسم الموسم"
            value={formData.seasonId}
            onChange={onChange}
            name="seasonId"
          >
            <option value="">اختر</option>
            {seasons.map((season) => (
              <option key={season.seasonId} value={season.seasonId}>
                {season.seasonName} - {season.seasonYear}
              </option>
            ))}
          </Select>

          <Button
            type="submit"
            text="تسجيل"
            isDisabled={
              !(
                (formData.merchantId || formData.fabricInstalmentId) &&
                formData.amount &&
                formData.instalmentDate &&
                formData.paymentMethod
              )
            }
          />
        </div>
      </div>
    </form>
  );
};

export default FabricInstalmentForm;
