import { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";

import useLocalStorage from "../../hooks/useLocalStorage";

import settings from "../../config";
import { format } from "../../utils";
import Header from "../Header";

const AccessoriesMerchantDetails = () => {
  const [accessoriesMerchantDetails, setAccessoriesMerchantDetails] = useState(
    [],
  );
  const { accessoriesMerchantId = "" } = useParams();
  const [admin] = useLocalStorage("admin");

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${settings.api}accessories-merchants/details`,
        {
          accessoriesMerchantId,
        },
      );
      if (response.data.statusCode === 200) {
        setAccessoriesMerchantDetails(response.data.data);
      }
    })();
  }, [accessoriesMerchantId]);

  return (
    <div className="w-full" dir="rtl">
      <Header title="تفاصيل تاجر اكسسوار" />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">مسلسل</th>
            <th className="p-4">اسم الاكسسوار</th>
            <th className="p-4">الوزن</th>
            <th className="p-4">الاجمالى</th>
            <th className="p-4">تاريخ الشراء</th>
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تعديل</th>
            )}
          </tr>
        </thead>
        <tbody>
          {accessoriesMerchantDetails.map((accessoriesMerchantDetail) => {
            return (
              <tr
                key={accessoriesMerchantDetail.accessoryTransactionId}
                className={`odd:bg-secondaryBg`}
              >
                <td className="p-4">
                  {accessoriesMerchantDetail.accessoryTransactionId}
                </td>
                <td className="text-center p-4">
                  {accessoriesMerchantDetail.accessoryName}
                </td>
                <td className="p-4">{accessoriesMerchantDetail.weight}</td>
                <td className="p-4">
                  {format(accessoriesMerchantDetail.totalPrice || 0)}
                </td>
                <td className="p-4">
                  {accessoriesMerchantDetail.transactionDate}
                </td>
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <div className="flex flex-col items-center w-full cursor-pointer">
                      <div className="font-bold rounded-full w-8">
                        <Link
                          to={`/accessory/transactions/form/${accessoriesMerchantDetail.accessoryId}/${accessoriesMerchantDetail.accessoryTransactionId}?from=accessories-merchant/details/${accessoriesMerchantId}`}
                        >
                          ✏
                        </Link>
                      </div>
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AccessoriesMerchantDetails;
