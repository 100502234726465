import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import settings from "../../config";
import Button from "../Button";
import { useParams } from "react-router-dom";
import Header from "../Header";
import Input from "../Input/Input";

const Inventory = () => {
  const [fabrics, setFabrics] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [operations, setOperations] = useState([]);
  const [updated, setUpdated] = useState(false);

  const { inventoryId } = useParams();

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}fabrics/`);

      if (response.data.statusCode === 200) {
        setFabrics(response.data.data);
      }

      const operationResponse = await axios.post(
        `${settings.api}inventory-operation/`,
        {
          inventoryId,
        },
      );

      if (operationResponse.data.statusCode === 200) {
        const operations = operationResponse.data.data;
        setOperations(operations);
      }
    })();
  }, [inventoryId]);

  useEffect(() => {
    if (operations.length && !updated) {
      const updatedFabrics = fabrics.map((fabric) => {
        const operation = operations.find(
          (operation) => operation.itemId === fabric.fabricId,
        );

        if (operation) {
          return {
            ...fabric,
            ...operation,
          };
        } else {
          return fabric;
        }
      });

      setFabrics(updatedFabrics);
      setUpdated(true);
    }
  }, [fabrics, operations, operations.length, updated]);

  const onChange = (fabricId, value) => {
    setFabrics(
      fabrics.map((fabric) =>
        fabric.fabricId === fabricId
          ? {
              ...fabric,
              actualQuantity: +value,
            }
          : fabric,
      ),
    );
  };

  const onSubmit = useCallback(async () => {
    const payload = {
      inventoryId,
      operations: fabrics.map((fabric) => ({
        itemId: fabric.fabricId,
        actualQuantity: fabric.actualQuantity,
        quantity: fabric.weight,
      })),
    };

    const response = await axios.post(
      `${settings.api}inventory-operation/create`,
      payload,
    );

    if (response.data.statusCode === 200) {
      setIsSubmitted(true);
    }
  }, [fabrics, inventoryId]);

  return (
    <div className="w-full" dir="rtl">
      <Header title="تفاصيل جرد" />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">اسم خامه القماش</th>
            <th className="p-4 w-40">الوزن المتاح</th>
            <th className="p-4 w-40">الوزن داخل المخزن</th>
          </tr>
        </thead>
        <tbody>
          {fabrics.map((fabric) => {
            return (
              <tr key={fabric.fabricId} className="odd:bg-secondaryBg">
                <td className="p-4">{fabric.fabricName}</td>

                <td className={`${!isSubmitted && "blur-md"} p-4`}>
                  {(fabric.weight || 0).toLocaleString("en-US")}
                </td>
                <td className="p-4">
                  <Input
                    onChange={(e) => onChange(fabric.fabricId, e.target.value)}
                    value={fabric.actualQuantity || 0}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-center">
        <Button className="w-1/2 my-5" text="جرد" action={onSubmit} />
      </div>
    </div>
  );
};

export default Inventory;
