import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Header from "../Header";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ModalContext } from "../../context";
import settings from "../../config";
import { format } from "../../utils";

const AccessoriesMerchants = () => {
  const [accessoriessMerchants, setAccessoriesMerchants] = useState([]);
  const [admin] = useLocalStorage("admin");
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${settings.api}accessories-merchants/`,
      );

      if (response.data.statusCode === 200) {
        setAccessoriesMerchants(response.data.data);
      }
    })();
  }, []);

  const deleteAccessoriesMerchant = async (accessoriesMerchantId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}accessories-merchants/delete`,
          {
            accessoriesMerchantId,
          },
        );

        if (response.data.statusCode === 200) {
          setAccessoriesMerchants(
            accessoriessMerchants.filter(
              (merchant) =>
                accessoriesMerchantId !== merchant.accessoriesMerchantId,
            ),
          );
        }
      },
    });
  };

  let remainingSum = 0;

  return (
    <div className="w-full" dir="rtl">
      <Header
        title="تجار الاكسسوارات"
        text="اضافه تاجر اكسسوارات"
        link="/accessories-merchant/form"
      />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">مسلسل</th>
            <th className="p-4">اسم تاجر الاكسسوار</th>
            <th className="p-4">الاجمالى</th>
            <th className="p-4">تم الدفع</th>
            <th className="p-4">المتبقى</th>
            <th className="text-center w-24 p-4">الدفعات</th>
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تعديل</th>
            )}
            {admin.canDelete === 1 && (
              <th className="text-center w-24 p-4">حذف</th>
            )}
          </tr>
        </thead>
        <tbody>
          {accessoriessMerchants.map((merchant) => {
            remainingSum += merchant.totalPrice - merchant.totalPaid;
            return (
              <tr
                key={merchant.accessoriesMerchantId}
                className="odd:bg-secondaryBg"
              >
                <td className="p-4">{merchant.accessoriesMerchantId}</td>
                <td className="p-4">
                  <Link
                    to={`/accessories-merchant/details/${merchant.accessoriesMerchantId}`}
                    className="hover:text-sky-500"
                  >
                    {merchant.accessoriesMerchantName}
                  </Link>
                </td>
                <td className="p-4">{format(merchant.totalPrice || 0)}</td>
                <td className="p-4">{format(merchant.totalPaid || 0)}</td>
                <td className="p-4">
                  {format(merchant.totalPrice - merchant.totalPaid || 0)}
                </td>
                <td className="text-center">
                  <div className="flex flex-col items-center w-full cursor-pointer">
                    <div className="font-bold rounded-full w-8">
                      <Link
                        to={`/accessory/instalments/${merchant.accessoriesMerchantId}`}
                      >
                        💵
                      </Link>
                    </div>
                  </div>
                </td>
                {admin.canEdit === 1 && (
                  <td className="text-center">
                    <Link
                      to={`/accessories-merchant/form/${merchant.accessoriesMerchantId}`}
                    >
                      ✏
                    </Link>
                  </td>
                )}

                {admin.canDelete === 1 && (
                  <td className="text-center">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() =>
                        deleteAccessoriesMerchant(
                          merchant.accessoriesMerchantId,
                        )
                      }
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
          <tr>
            <td colSpan={4} className="text-rose-500 p-4">
              اجمالى المتبقى
            </td>
            <td className="text-rose-500 p-4">{format(remainingSum || 0)}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AccessoriesMerchants;
