import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import settings from "../../config";
import Button from "../Button";
import { useParams } from "react-router-dom";
import Header from "../Header";
import Input from "../Input/Input";

const Inventory = () => {
  const [accessories, setAccessories] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [operations, setOperations] = useState([]);
  const [updated, setUpdated] = useState(false);

  const { inventoryId } = useParams();

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}accessories/`);

      if (response.data.statusCode === 200) {
        setAccessories(response.data.data);
      }

      const operationResponse = await axios.post(
        `${settings.api}inventory-operation/`,
        {
          inventoryId,
        },
      );

      if (operationResponse.data.statusCode === 200) {
        const operations = operationResponse.data.data;
        setOperations(operations);
      }
    })();
  }, [inventoryId]);

  useEffect(() => {
    if (operations.length && !updated) {
      const updatedAccessories = accessories.map((accessory) => {
        const operation = operations.find(
          (operation) => operation.itemId === accessory.accessoryId,
        );

        if (operation) {
          return {
            ...accessory,
            ...operation,
          };
        } else {
          return accessory;
        }
      });

      setAccessories(updatedAccessories);
      setUpdated(true);
    }
  }, [accessories, operations, operations.length, updated]);

  const onChange = (accessoryId, value) => {
    setAccessories(
      accessories.map((accessory) =>
        accessory.accessoryId === accessoryId
          ? {
              ...accessory,
              actualQuantity: +value,
            }
          : accessory,
      ),
    );
  };

  const onSubmit = useCallback(async () => {
    const payload = {
      inventoryId,
      operations: accessories.map((accessory) => ({
        itemId: accessory.accessoryId,
        actualQuantity: accessory.actualQuantity,
        quantity: accessory.weight - accessory.usedWeight || 0,
      })),
    };

    const response = await axios.post(
      `${settings.api}inventory-operation/create`,
      payload,
    );

    if (response.data.statusCode === 200) {
      setIsSubmitted(true);
    }
  }, [accessories, inventoryId]);

  return (
    <div className="w-full" dir="rtl">
      <Header title="تفاصيل جرد" />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">اسم خامه القماش</th>
            <th className="p-4 w-40">الوزن المتاح</th>
            <th className="p-4 w-40">الوزن داخل المخزن</th>
          </tr>
        </thead>
        <tbody>
          {accessories.map((accessory) => {
            return (
              <tr key={accessory.accessoryId} className="odd:bg-secondaryBg">
                <td className="p-4">{accessory.accessoryName}</td>

                <td className={`${!isSubmitted && "blur-md"} p-4`}>
                  {(
                    (accessory.weight || 0) - (accessory.usedWeight || 0)
                  ).toLocaleString("en-US")}
                </td>
                <td className="p-4">
                  <Input
                    onChange={(e) =>
                      onChange(accessory.accessoryId, e.target.value)
                    }
                    value={accessory.actualQuantity || 0}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-center">
        <Button className="w-1/2 my-5" text="جرد" action={onSubmit} />
      </div>
    </div>
  );
};

export default Inventory;
