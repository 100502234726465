import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";
import Input from "../Input/Input";
import Select from "../Select/Select";
import Header from "../Header";

const AccessoryInstalmentForm = () => {
  const navigate = useNavigate();
  const {
    accessoryId = "",
    accessoriesMerchantId = "",
    accessoryInstalmentId = "",
  } = useParams();

  const [formData, setFormData] = useState({
    accessoriesMerchantId,
    accessoryId,
    amount: 0,
    discount: 0,
    instalmentDate: "",
    paymentMethod: "",
    seasonId: "",
  });

  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}seasons/`);

      if (response.data.statusCode === 200) {
        setSeasons(response.data.data);
      }
    })();
  }, []);

  useEffect(() => {
    if (accessoryInstalmentId) {
      (async () => {
        const response = await axios.post(
          `${settings.api}accessory/view-instalment`,
          {
            accessoryInstalmentId,
          },
        );

        setFormData({
          accessoriesMerchantId,
          ...response.data.data[0],
        });
      })();
    }
  }, [accessoryInstalmentId, accessoriesMerchantId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        const response = accessoryInstalmentId
          ? await axios.post(
              `${settings.api}accessory/update-instalment`,
              formData,
            )
          : await axios.post(
              `${settings.api}accessory/create-instalment`,
              formData,
            );

        if (response.data.statusCode === 200) {
          navigate(`/accessory/instalments/${accessoriesMerchantId}`);
        }
      }}
    >
      <div className="w-full">
        <Header title="استماره دفعات الاكسسوارات" />

        <div className="flex flex-col w-full lg:w-1/2 m-auto gap-4">
          <Input
            label="الدفعه"
            value={formData.amount}
            name="amount"
            onChange={onChange}
            required
          />

          <Input
            label="الخصم"
            value={formData.discount}
            name="discount"
            onChange={onChange}
            required
          />

          <Input
            label="تاريخ الدفعه"
            placeholder="yyyy-mm-dd"
            value={formData.instalmentDate}
            name="instalmentDate"
            onChange={onChange}
            required
            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
            type="date"
          />

          <Select
            label="طريقه الدفع"
            value={formData.paymentMethod}
            onChange={onChange}
            required
            name="paymentMethod"
          >
            <option value="">اختر</option>
            <option value="نقدى">نقدى</option>
            <option value="فودافون كاش">فودافون كاش</option>
            <option value="اتصالات كاش">اتصالات كاش</option>
            <option value="بوسطه">بوسطه</option>
            <option value="بنك">بنك</option>
            <option value="شيك">شيك</option>
          </Select>

          <Select
            label="اسم الموسم"
            value={formData.seasonId}
            onChange={onChange}
            name="seasonId"
          >
            <option value="">اختر</option>
            {seasons.map((season) => (
              <option key={season.seasonId} value={season.seasonId}>
                {season.seasonName} - {season.seasonYear}
              </option>
            ))}
          </Select>

          <Button
            type="submit"
            text="تسجيل"
            isDisabled={
              !(
                formData.accessoriesMerchantId &&
                formData.amount &&
                formData.instalmentDate &&
                formData.paymentMethod
              )
            }
          />
        </div>
      </div>
    </form>
  );
};

export default AccessoryInstalmentForm;
