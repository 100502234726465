import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import Header from "../Header";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ModalContext } from "../../context";

import settings from "../../config";
import { format } from "../../utils";

const Sell = () => {
  const [variantClient, setVariantClient] = useState([]);
  const { fabricTransactionVariantId = "" } = useParams();
  const [admin] = useLocalStorage("admin");
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}variant-client/`, {
        fabricTransactionVariantId,
      });

      if (response.data.statusCode === 200) {
        setVariantClient(response.data.data);
      }
    })();
  }, [fabricTransactionVariantId]);

  const deleteVariantClient = async (variantClientId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}variant-client/delete`,
          {
            variantClientId: variantClientId,
          },
        );

        if (response.data.statusCode === 200) {
          setVariantClient(
            variantClient.filter(
              (variant) => variantClientId !== variant.variantClientId,
            ),
          );
        }
      },
    });
  };

  let totalWithDiscount = 0;

  return (
    <div className="w-full" dir="rtl">
      <Header
        title="بيع الاصناف"
        text="بيع"
        link={`/variant/sell/form/${fabricTransactionVariantId}`}
      />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">كود العميل</th>
            <th className="p-4">اسم العميل</th>
            <th className="p-4">الكميه</th>
            <th className="p-4">سعر القطعه بالربح</th>
            <th className="p-4">الاجمالى بالربح</th>
            <th className="p-4">الاجمالى بالربح والخصم</th>
            <th className="p-4">الموسم</th>
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تعديل</th>
            )}
            {admin.canDelete === 1 && (
              <th className="text-center w-24 p-4">حذف</th>
            )}
          </tr>
        </thead>
        <tbody>
          {variantClient.map((variantClientDetail) => {
            totalWithDiscount = variantClientDetail.finalPrice;

            if (variantClientDetail.discountType === 2) {
              totalWithDiscount =
                variantClientDetail.finalPrice -
                variantClientDetail.quantity * variantClientDetail.discount;
            }

            if (variantClientDetail.discountType === 1) {
              totalWithDiscount =
                variantClientDetail.finalPrice -
                (variantClientDetail.finalPrice *
                  variantClientDetail.discount) /
                  100;
            }

            return (
              <tr
                key={variantClientDetail.variantClientId}
                className="odd:bg-secondaryBg"
              >
                <td className="p-4">{variantClientDetail.clientCode}</td>
                <td className="p-4">{variantClientDetail.clientName}</td>
                <td className="p-4">{variantClientDetail.quantity || 0}</td>
                <td className="p-4">
                  {variantClientDetail.finalPrice /
                    variantClientDetail.quantity || 0}
                </td>
                <td className="p-4">
                  {format(variantClientDetail.finalPrice || 0)}
                </td>
                <td className="p-4">{format(totalWithDiscount || 0)}</td>
                <td className="p-4">{variantClientDetail.seasonName}</td>
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <Link
                      to={`/variant/sell/form/${fabricTransactionVariantId}/${variantClientDetail.variantClientId}`}
                    >
                      ✏
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() =>
                        deleteVariantClient(variantClientDetail.variantClientId)
                      }
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Sell;
