import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import Header from "../Header";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ModalContext } from "../../context";

import settings from "../../config";
import { format } from "../../utils";
const AccessoryTransactions = () => {
  const [accessoryTransactions, setAccessoryTransactions] = useState([]);
  const { accessoryId } = useParams();
  const [admin] = useLocalStorage("admin");
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${settings.api}accessory/transactions`,
        {
          accessoryId,
        },
      );

      if (response.data.statusCode === 200) {
        setAccessoryTransactions(response.data.data);
      }
    })();
  }, [accessoryId]);

  const deleteAccessoryTransaction = async (accessoryTransactionId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}accessory/delete-transaction`,
          {
            accessoryTransactionId,
          },
        );

        if (response.data.statusCode === 200) {
          setAccessoryTransactions(
            accessoryTransactions.filter(
              (accessoryTransaction) =>
                accessoryTransactionId !==
                accessoryTransaction.accessoryTransactionId,
            ),
          );
        }
      },
    });
  };

  return (
    <div className="w-full" dir="rtl">
      <Header
        title="معاملات اكسسوار"
        text="اضافه معامله اكسسوار"
        link={`/accessory/transactions/form/${accessoryId}`}
      />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">اسم الاكسسوار</th>
            <th className="p-4">اسم تاجر الاكسسوار</th>
            <th className="p-4">سعر القطعه</th>
            <th className="p-4">عدد القطع</th>
            <th className="p-4">السعر الاجمالى</th>
            <th className="p-4">تاريخ المعامله</th>
            <th className="p-4">الموسم</th>
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تعديل</th>
            )}
            {admin.canDelete === 1 && (
              <th className="text-center w-24 p-4">حذف</th>
            )}
          </tr>
        </thead>
        <tbody>
          {accessoryTransactions.map((accessoryTransaction) => {
            return (
              <tr
                key={accessoryTransaction.accessoryTransactionId}
                className="odd:bg-secondaryBg"
              >
                <td className="p-4">{accessoryTransaction.accessoryName}</td>
                <td className="p-4">
                  {accessoryTransaction.accessoriesMerchantName}
                </td>
                <td className="p-4">
                  {format(accessoryTransaction.price || 0)}
                </td>
                <td className="p-4">{accessoryTransaction.weight || 0}</td>
                <td className="p-4">
                  {format(
                    accessoryTransaction.price * accessoryTransaction.weight ||
                      0,
                  )}
                </td>
                <td className="p-4">{accessoryTransaction.transactionDate}</td>
                <td className="p-4">{accessoryTransaction.seasonName}</td>
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <Link
                      to={`/accessory/transactions/form/${accessoryId}/${accessoryTransaction.accessoryTransactionId}`}
                    >
                      ✏
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() =>
                        deleteAccessoryTransaction(
                          accessoryTransaction.accessoryTransactionId,
                        )
                      }
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AccessoryTransactions;
