import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import useLocalStorage from "../../hooks/useLocalStorage";
import { ModalContext } from "../../context";
import settings from "../../config";
import Header from "../Header";

const AssetDetails = () => {
  const [assetDetails, setAssetDetails] = useState([]);
  const { assetId } = useParams();
  const [admin] = useLocalStorage("admin");
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}assets/details`, {
        assetId,
      });

      if (response.data.statusCode === 200) {
        setAssetDetails(response.data.data);
      }
    })();
  }, [assetId]);

  const deleteBillCategoryInstalment = async (assetDetailId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}assets/delete-details`,
          {
            assetDetailId,
          },
        );

        if (response.data.statusCode === 200) {
          setAssetDetails(
            assetDetails.filter(
              (assetDetail) => assetDetailId !== assetDetail.assetDetailId,
            ),
          );
        }
      },
    });
  };

  let total = 0;

  return (
    <div className="w-full" dir="rtl">
      <Header
        title="تفاصيل اصل"
        text="اضافه تفاصيل اصل"
        link={`/asset/details/form/${assetId}`}
      />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">اسم تفاصيل الاصل</th>
            <th className="p-4">العدد</th>
            <th className="p-4">السعر للقطعه</th>
            <th className="p-4">السعر الاجمالى</th>
            <th className="p-4">الموسم</th>
            <th className="p-4">تاريخ</th>
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تعديل</th>
            )}
            {admin.canDelete === 1 && (
              <th className="text-center w-24 p-4">حذف</th>
            )}
          </tr>
        </thead>
        <tbody>
          {assetDetails.map((assetDetail) => {
            total += assetDetail.price * assetDetail.quantity;

            return (
              <tr
                key={assetDetail.assetDetailId}
                className="odd:bg-secondaryBg"
              >
                <td className="p-4">{assetDetail.assetName}</td>
                <td className="p-4">{assetDetail.quantity}</td>
                <td className="p-4">{assetDetail.price}</td>
                <td className="p-4">
                  {assetDetail.price * assetDetail.quantity}
                </td>
                <td className="p-4">{assetDetail.seasonName}</td>
                <td className="p-4">{assetDetail.instalmentDate}</td>
                {admin.canEdit === 1 && (
                  <td className="text-center">
                    <Link
                      to={`/asset/details/form/${assetId}/${assetDetail.assetDetailId}`}
                      className="flex flex-col items-center w-full cursor-pointer p-4"
                    >
                      ✏
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer p-4"
                      onClick={() =>
                        deleteBillCategoryInstalment(assetDetail.assetDetailId)
                      }
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
          <tr>
            <td className="text-rose-500 p-4">اجمالى المدفوع</td>
            <td></td>
            <td></td>
            <td className="text-rose-500 p-4">{total}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AssetDetails;
