import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import Header from "../Header";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ModalContext } from "../../context";

import settings from "../../config";
import { format } from "../../utils";

const FabricTransactions = () => {
  const [fabricTransactions, setFabricTransactions] = useState([]);
  const { fabricId } = useParams();
  const [admin] = useLocalStorage("admin");
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}fabric/transactions`, {
        fabricId,
        transactionType: 1,
      });

      if (response.data.statusCode === 200) {
        setFabricTransactions(response.data.data);
      }
    })();
  }, [fabricId]);

  const deleteFabricTransaction = async (fabricTransactionId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}fabric/delete-transaction`,
          {
            fabricTransactionId,
          },
        );

        if (response.data.statusCode === 200) {
          setFabricTransactions(
            fabricTransactions.filter(
              (fabricTransaction) =>
                fabricTransactionId !== fabricTransaction.fabricTransactionId,
            ),
          );
        }
      },
    });
  };

  return (
    <div className="w-full" dir="rtl">
      <Header
        title="معاملات القماش"
        text="شراء قماش"
        link={`/fabric/transactions/form/${fabricId}`}
      />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">مسلسل</th>
            <th className="p-4">اسم خامه القماش</th>
            <th className="p-4">اسم التاجر</th>
            <th className="p-4">سعر الكيلو</th>
            <th className="p-4">الوزن</th>
            <th className="p-4">السعر الاجمالى</th>
            <th className="p-4">المدفوع</th>
            <th className="p-4">المتبقى</th>
            <th className="p-4">تاريخ الشراء</th>
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تعديل</th>
            )}
            {admin.canDelete === 1 && (
              <th className="text-center w-24 p-4">حذف</th>
            )}
          </tr>
        </thead>
        <tbody>
          {fabricTransactions.map((fabricTransaction) => {
            return (
              <tr
                key={fabricTransaction.fabricTransactionId}
                className="odd:bg-secondaryBg"
              >
                <td className="p-4">{fabricTransaction.fabricTransactionId}</td>
                <td className="p-4">{fabricTransaction.fabricName}</td>
                <td className="p-4">{fabricTransaction.merchantName}</td>
                <td className="p-4">{format(fabricTransaction.price || 0)}</td>
                <td className="p-4">
                  {(fabricTransaction.weight || 0).toLocaleString("en-US")}
                </td>
                <td
                  className={`${
                    fabricTransaction.transactionType === 1 &&
                    ((fabricTransaction.totalPaid ===
                      fabricTransaction.price * fabricTransaction.weight &&
                      "text-green-500") ||
                      "text-rose-500")
                  } p-4`}
                >
                  {format(
                    fabricTransaction.price * fabricTransaction.weight || 0,
                  )}
                </td>
                <td className="p-4">
                  {format(fabricTransaction.totalPaid || 0)}
                </td>
                <td className="p-4">
                  {format(
                    fabricTransaction.price * fabricTransaction.weight -
                      fabricTransaction.totalPaid || 0,
                  )}
                </td>
                <td className="p-4">{fabricTransaction.transactionDate}</td>
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <Link
                      to={`/fabric/transactions/form/${fabricId}/${fabricTransaction.fabricTransactionId}`}
                      className="flex flex-col items-center w-full cursor-pointer"
                    >
                      ✏
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() =>
                        deleteFabricTransaction(
                          fabricTransaction.fabricTransactionId,
                        )
                      }
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FabricTransactions;
