import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";
import Input from "../Input/Input";
import Header from "../Header";
import Select from "../Select/Select";

const AccessoryTransactionForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { accessoryId = "", accessoryTransactionId = "" } = useParams();
  const [accessory, setAccessory] = useState({});
  const [accessoriesMerchants, setMerchants] = useState([]);

  const [formData, setFormData] = useState({
    accessoryId,
    accessoriesMerchantId: "",
    price: 0,
    weight: "",
    transactionType: 1,
    transactionDate: "",
    seasonId: "",
  });

  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}seasons/`);

      if (response.data.statusCode === 200) {
        setSeasons(response.data.data);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}accessories/view`, {
        accessoryId,
      });

      if (response.data.statusCode === 200) {
        setAccessory(response.data.data[0]);
      }
    })();

    (async () => {
      const response = await axios.post(
        `${settings.api}accessories-merchants/`,
      );

      if (response.data.statusCode === 200) {
        setMerchants(response.data.data);
      }
    })();
  }, [accessoryId]);

  useEffect(() => {
    if (accessoryTransactionId) {
      (async () => {
        const response = await axios.post(
          `${settings.api}accessory/view-transaction`,
          {
            accessoryTransactionId,
          },
        );

        setFormData(response.data.data[0]);
      })();
    }
  }, [accessoryTransactionId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        const response = accessoryTransactionId
          ? await axios.post(
              `${settings.api}accessory/update-transaction`,
              formData,
            )
          : await axios.post(
              `${settings.api}accessory/create-transaction`,
              formData,
            );

        if (response.data.statusCode === 200) {
          if (searchParams.get("from")) {
            navigate(`/${searchParams.get("from")}`);
          } else {
            navigate(`/accessory/transactions/${accessoryId}`);
          }
        }
      }}
    >
      <div className="w-full">
        <Header title="استماره معامله اكسسوار" />

        <div className="flex flex-col w-full lg:w-1/2 m-auto gap-4">
          <Input
            label="اسم الاكسسوار"
            defaultValue={accessory.accessoryName}
            disabled
          />

          <Select
            label="اسم تاجر الاكسسوار"
            value={formData.accessoriesMerchantId}
            onChange={onChange}
            name="accessoriesMerchantId"
          >
            <option value={0}>اختر</option>
            {accessoriesMerchants.map((accessoriesMerchant) => (
              <option
                key={accessoriesMerchant.accessoriesMerchantId}
                value={accessoriesMerchant.accessoriesMerchantId}
              >
                {accessoriesMerchant.accessoriesMerchantName}
              </option>
            ))}
          </Select>

          <Input
            label="سعر القطعه"
            value={formData.price}
            name="price"
            onChange={onChange}
          />

          <Input
            label="عدد القطع"
            value={formData.weight}
            name="weight"
            onChange={onChange}
            required
          />

          <Input
            label="السعر الاجمالى"
            value={formData.price * formData.weight || 0}
            disabled
          />

          <Input
            label="تاريخ المعامله"
            placeholder="yyyy-mm-dd"
            value={formData.transactionDate}
            name="transactionDate"
            onChange={onChange}
            required
            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
            type="date"
          />

          <Select
            label="اسم الموسم"
            value={formData.seasonId}
            onChange={onChange}
            name="seasonId"
          >
            <option value="">اختر</option>
            {seasons.map((season) => (
              <option key={season.seasonId} value={season.seasonId}>
                {season.seasonName} - {season.seasonYear}
              </option>
            ))}
          </Select>

          <Button
            type="submit"
            text="تسجيل"
            isDisabled={
              !(
                formData.accessoryId &&
                formData.weight &&
                formData.transactionType &&
                formData.transactionDate
              )
            }
          />
        </div>
      </div>
    </form>
  );
};

export default AccessoryTransactionForm;
