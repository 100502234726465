import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";
import Header from "../Header";
import Input from "../Input/Input";
import Select from "../Select/Select";

const BillCategoryInstalmentForm = () => {
  const navigate = useNavigate();
  const { billCategoryId = "", billId = null } = useParams();

  const [formData, setFormData] = useState({
    billCategoryId,
    amount: 0,
    discount: 0,
    instalmentDate: "",
    paymentMethod: "",
    billType: 0,
    seasonId: "",
  });

  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}seasons/`);

      if (response.data.statusCode === 200) {
        setSeasons(response.data.data);
      }
    })();
  }, []);

  useEffect(() => {
    if (billId) {
      (async () => {
        const response = await axios.post(
          `${settings.api}bill-categories/view-instalment`,
          {
            billId,
          },
        );

        setFormData(response.data.data[0]);
      })();
    }
  }, [billId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      dir="rtl"
      onSubmit={async (e) => {
        e.preventDefault();

        const response =
          (billId &&
            (await axios.post(
              `${settings.api}bill-categories/update-instalment`,
              formData,
            ))) ||
          (await axios.post(
            `${settings.api}bill-categories/create-instalment`,
            formData,
          ));

        if (response.data.statusCode === 200) {
          navigate(`/bill-category/instalments/${billCategoryId}`);
        }
      }}
    >
      <div className="w-full">
        <Header title="استماره دفعات المدفوعات" />

        <div className="flex flex-col w-full lg:w-1/2 m-auto gap-4">
          <Input
            label="الدفعه"
            value={formData.amount}
            onChange={onChange}
            name="amount"
            required
          />

          <Input
            label="الخصم"
            value={formData.discount}
            onChange={onChange}
            name="discount"
          />

          <Select
            label="نوع الدفعه"
            value={formData.billType}
            onChange={onChange}
            name="billType"
            required
          >
            <option value="">اختر</option>
            <option value={1}>داخل</option>
            <option value={-1}>خارج</option>
          </Select>

          <Input
            label="تاريخ الدفعه"
            value={formData.instalmentDate}
            onChange={onChange}
            name="instalmentDate"
            placeholder="yyyy-mm-dd"
            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
            required
            type="date"
          />

          <Select
            label="طريقه الدفع"
            value={formData.paymentMethod}
            onChange={onChange}
            name="paymentMethod"
            required
          >
            <option value="">اختر</option>
            <option value="نقدى">نقدى</option>
            <option value="فودافون كاش">فودافون كاش</option>
            <option value="اتصالات كاش">اتصالات كاش</option>
            <option value="بوسطه">بوسطه</option>
            <option value="بنك">بنك</option>
            <option value="شيك">شيك</option>
          </Select>

          <Select
            label="اسم الموسم"
            value={formData.seasonId}
            onChange={onChange}
            name="seasonId"
          >
            <option value="">اختر</option>
            {seasons.map((season) => (
              <option key={season.seasonId} value={season.seasonId}>
                {season.seasonName} - {season.seasonYear}
              </option>
            ))}
          </Select>

          <Button
            className="w-1/2 m-auto"
            type="submit"
            text="تسجيل"
            isDisabled={
              !(
                formData.billCategoryId &&
                formData.amount &&
                formData.instalmentDate &&
                formData.paymentMethod &&
                formData.billType
              )
            }
          />
        </div>
      </div>
    </form>
  );
};

export default BillCategoryInstalmentForm;
