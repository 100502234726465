import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import Header from "../Header";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ModalContext } from "../../context";

import settings from "../../config";
import { format } from "../../utils";

const VariantInstalment = () => {
  const [variantClientInstalments, setVariantClientInstalments] = useState([]);
  const { clientId = "" } = useParams();
  const [admin] = useLocalStorage("admin");
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${settings.api}variant-client/instalments`,
        {
          clientId,
        },
      );

      if (response.data.statusCode === 200) {
        setVariantClientInstalments(response.data.data);
      }
    })();
  }, [clientId]);

  const deleteVariantClientInstalment = async (variantClientInstalmentId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}variant-client/delete-instalment`,
          {
            variantClientInstalmentId: variantClientInstalmentId,
          },
        );

        if (response.data.statusCode === 200) {
          setVariantClientInstalments(
            variantClientInstalments.filter(
              (variant) =>
                variantClientInstalmentId !== variant.variantClientInstalmentId,
            ),
          );
        }
      },
    });
  };

  let totalPaid = 0;

  return (
    <div className="w-full" dir="rtl">
      <Header
        title="دفعات الاصناف"
        text="استلام دفعه"
        link={`/variant/instalments/form/${clientId}`}
      />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">كود العميل</th>
            <th className="p-4">اسم العميل</th>
            <th className="p-4">المبلغ الاجمالى</th>
            <th className="p-4">قيمه الدفعه</th>
            <th className="p-4">خصم</th>
            <th className="p-4">المتبقى</th>
            <th className="p-4">تاريخ الدفعه</th>
            <th className="p-4">الموسم</th>
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تعديل</th>
            )}
            {admin.canDelete === 1 && (
              <th className="text-center w-24 p-4">حذف</th>
            )}
          </tr>
        </thead>
        <tbody>
          {variantClientInstalments.map((variantClientDetail) => {
            totalPaid +=
              variantClientDetail.paid + variantClientDetail.discount;
            return (
              <tr
                key={variantClientDetail.variantClientId}
                className="odd:bg-secondaryBg"
              >
                <td className="p-4">{variantClientDetail.clientCode}</td>
                <td className="p-4">{variantClientDetail.clientName}</td>
                <td className="p-4">
                  {format(variantClientDetail.amount || 0)}
                </td>
                <td className="p-4">{format(variantClientDetail.paid || 0)}</td>
                <td className="p-4">
                  {format(variantClientDetail.discount || 0)}
                </td>
                <td className="p-4">
                  {format(variantClientDetail.amount - totalPaid || 0)}
                </td>
                <td className="p-4">{variantClientDetail.instalmentDate}</td>
                <td className="p-4">{variantClientDetail.seasonName}</td>
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <Link
                      to={`/variant/instalments/form/${clientId}/${variantClientDetail.variantClientInstalmentId}`}
                    >
                      ✏
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() =>
                        deleteVariantClientInstalment(
                          variantClientDetail.variantClientInstalmentId,
                        )
                      }
                    >
                      ❌
                    </div>
                  </td>
                )}{" "}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default VariantInstalment;
