import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";
import Header from "../Header";
import Select from "../Select/Select";
import Input from "../Input/Input";

const FabricVariantTransactionForm = () => {
  const navigate = useNavigate();
  const { accessoryId = "", accessoryVariantTransactionId = null } =
    useParams();
  const [variants, setVariants] = useState([]);

  const [formData, setFormData] = useState({
    accessoryId,
    accessoryVariantTransactionId,
    fabricTransactionVariantId: "",
    quantity: "",
    price: "",
    processingDate: "",
    seasonId: "",
  });

  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}seasons/`);

      if (response.data.statusCode === 200) {
        setSeasons(response.data.data);
      }
    })();
  }, []);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${settings.api}fabric/variants-transactions`,
      );

      setVariants(response.data.data);
    })();
  }, []);

  useEffect(() => {
    if (accessoryVariantTransactionId) {
      (async () => {
        const response = await axios.post(
          `${settings.api}accessory/variant-view-transaction`,
          {
            accessoryVariantTransactionId,
          },
        );

        setFormData(response.data.data[0]);
      })();
    }
  }, [accessoryVariantTransactionId]);

  return (
    <form
      dir="rtl"
      onSubmit={async (e) => {
        e.preventDefault();

        const response =
          (accessoryVariantTransactionId &&
            (await axios.post(
              `${settings.api}accessory/update-variant-transaction`,
              formData,
            ))) ||
          (await axios.post(
            `${settings.api}accessory/create-variant-transaction`,
            formData,
          ));

        if (response.data.statusCode === 200) {
          navigate(`/accessory/variants/${accessoryId}`);
        }
      }}
    >
      <div className="w-full">
        <Header title="استماره دفعات الاكسسوارت" />

        <div className="flex flex-col w-full lg:w-1/2 m-auto gap-4">
          <Select
            label="الاصناف"
            value={formData.fabricTransactionVariantId}
            name="fabricTransactionVariantId"
            onChange={onChange}
            required
            pattern="[0-9]{4}-[0-9]2}-[0-9]{2}"
          >
            <option value="">اختر</option>
            {variants.map((variant) => (
              <option
                key={variant.fabricTransactionVariantId}
                value={variant.fabricTransactionVariantId}
              >
                {`${variant.variantCode} - ${variant.variantName}`}
              </option>
            ))}
          </Select>

          <Input
            label="العدد"
            value={formData.quantity}
            name="quantity"
            onChange={onChange}
            required
          />

          <Input
            label="التكلفه للقطعه"
            value={formData.price}
            name="price"
            onChange={onChange}
            required
          />

          <Input
            label="التكلفه الاجماليه"
            value={
              formData.price *
                variants.find(
                  (variant) =>
                    variant.fabricTransactionVariantId ===
                    +formData.fabricTransactionVariantId,
                )?.quantity || 0
            }
            name="price"
            onChange={onChange}
            required
            disabled
          />

          <Input
            label="تاريخ الدفعه"
            placeholder="yyyy-mm-dd"
            value={formData.processingDate}
            name="processingDate"
            onChange={onChange}
            required
            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
            type="date"
          />

          <Select
            label="اسم الموسم"
            value={formData.seasonId}
            onChange={onChange}
            name="seasonId"
          >
            <option value="">اختر</option>
            {seasons.map((season) => (
              <option key={season.seasonId} value={season.seasonId}>
                {season.seasonName} - {season.seasonYear}
              </option>
            ))}
          </Select>

          <Button
            type="submit"
            text="تسجيل"
            isDisabled={
              !(
                (formData.fabricTransactionVariantId || formData.accessoryId) &&
                formData.quantity &&
                formData.processingDate &&
                formData.price
              )
            }
          />
        </div>
      </div>
    </form>
  );
};

export default FabricVariantTransactionForm;
