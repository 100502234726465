import { Routes, Route } from "react-router-dom";

import Login from "../Login";
import Dashboard from "../Dashboard";

const Layout = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Login />} />
      <Route path="/*" exact element={<Dashboard />} />
    </Routes>
  );
};

export default Layout;
