import { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";
import logo from "../../imgs/albasha-logo.png";
import Input from "../Input/Input";

const ClientInvoices = () => {
  const { clientId } = useParams();
  const [data, setData] = useState({
    purchases: [],
    amount: 0,
    balance: 0,
  });

  const [formData, setFormData] = useState({
    transactionDate: "",
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  let grandTotal = 0;
  let fullPrice = 0;
  let discountTotal = 0;
  return (
    <form
      dir="rtl"
      onSubmit={async (e) => {
        e.preventDefault();
        (async () => {
          const response = await axios.post(`${settings.api}clients/invoices`, {
            clientId,
            transactionDate: formData.transactionDate,
          });

          if (response.data.data) {
            setData(response.data.data);
          }
        })();
      }}
    >
      <div className="w-full">
        <div className="w-full rounded-xl divide-solid divide-y flex flex-col px-4">
          <div className="flex flex-col py-4">
            <div className="flex flex-row justify-center space-x-10 w-full">
              <div className="w-full font-bold text-xl text-center mb-5">
                فاتوره العميل
              </div>
            </div>
            <div className="flex space-x-10 w-full justify-center items-center print:hidden">
              <label className="w-1/2">
                <Input
                  label="تاريخ الفاتوره"
                  value={formData.transactionDate}
                  onChange={onChange}
                  required
                  name="transactionDate"
                />
              </label>
              <div className="w-1/2 text-center">
                <div className="pt-6">
                  <Button
                    type="submit"
                    text="بحث"
                    isDisabled={!formData.transactionDate}
                    className="mx-auto text-center w-1/2"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-center space-x-10 w-full mt-5">
              {(data?.purchases && data?.purchases.length && (
                <div className="flex w-full flex-col">
                  <div className="flex w-full items-center">
                    <div className="w-1/3 text-center">
                      مصنع الباشا <br /> للملابس الجاهزه
                    </div>
                    <div className="w-1/3 text-center">قاتورة</div>
                    <div className="w-1/3 text-center flex flex-col justify-center items-center">
                      <img src={logo} alt="logo" className="w-24" />
                    </div>
                  </div>
                  <div className="flex w-full mt-5">
                    <span className=" text-rose-600 ml-5">تحريرا فى </span>{" "}
                    {formData.transactionDate}
                  </div>
                  <div className="flex w-full mb-5">
                    <span className=" text-rose-600 ml-5">
                      المطلوب من السيد
                    </span>{" "}
                    {data?.purchases[0]?.clientName}
                  </div>
                  <hr className="my-5" />
                  <div className="flex w-full">
                    <table
                      className="table w-full"
                      cellSpacing={10}
                      cellPadding={10}
                    >
                      <thead>
                        <tr className="border-b">
                          <th>جنيه</th>
                          {/* <th>الخصم</th> */}
                          <th>العدد</th>
                          <th>الفئه</th>
                          <th>الصنف</th>
                          <th>كود</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.purchases &&
                          data?.purchases.length &&
                          data.purchases.map((row, index) => {
                            fullPrice = row.clientPrice;

                            if (row.discountType === 1) {
                              fullPrice =
                                fullPrice - (fullPrice * row.discount) / 100;

                              grandTotal += fullPrice * row.quantity;
                            } else if (row.discountType === 2) {
                              fullPrice = fullPrice - row.discount;

                              grandTotal += fullPrice * row.quantity;
                            } else {
                              grandTotal += fullPrice * row.quantity;
                            }

                            discountTotal +=
                              (row.clientPrice - fullPrice) * row.quantity;

                            return (
                              <tr key={index} className="text-center border-b">
                                <td>{row.clientPrice * row.quantity}</td>
                                {/* <td>
                                  {row.discountType === 1 && `${row.discount}%`}
                                  {row.discountType === 2 && `${row.discount}`}
                                  {!row.discountType && 0}
                                </td> */}
                                <td>{row.quantity}</td>
                                <td>{row.clientPrice}</td>
                                <td>{row.variantType}</td>
                                <td>{row.variantCode}</td>
                              </tr>
                            );
                          })}
                        {discountTotal !== 0 && (
                          <tr>
                            <td className="text-center ">{discountTotal}</td>
                            <td className="" colSpan={3}>
                              الخصم
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td className="text-center ">{grandTotal}</td>
                          <td className="" colSpan={3}>
                            الاجمالى
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center ">
                            {data.balance - data.remaining}
                          </td>
                          <td className="" colSpan={3}>
                            الرصيد السابق
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center ">
                            {grandTotal + (data.balance - data.remaining)}
                          </td>
                          <td className="" colSpan={3}>
                            اجمالى الفاتوره
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )) ||
                null}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ClientInvoices;
