import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import useLocalStorage from "../../hooks/useLocalStorage";
import { ModalContext } from "../../context";

import settings from "../../config";
import Header from "../Header";

const Variants = () => {
  const [variants, setVariants] = useState([]);
  const { fabricId = "" } = useParams();
  const [admin] = useLocalStorage("admin");
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}variants/`);

      if (response.data.statusCode === 200) {
        setVariants(response.data.data);
      }
    })();
  }, []);

  const deleteFabric = async (variantId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(`${settings.api}variants/delete`, {
          variantId,
        });

        if (response.data.statusCode === 200) {
          setVariants(
            variants.filter((variant) => variantId !== variant.variantId),
          );
        }
      },
    });
  };

  let totalQuantity = 0;
  let totalSold = 0;
  let totalRemaining = 0;

  return (
    <div className="w-full" dir="rtl">
      <Header
        title="الاصناف"
        text="اضافه صنف"
        link={`/variant/form/${fabricId}`}
      />
      <div className="flex gap-x-5 flex-row mb-5">
        <div className="flex w-full font-bold cursor-pointer">
          <Link
            to="/variant/search"
            className=" bg-secondaryBg rounded-xl text-white p-3"
          >
            🔍 بحث بكود الصنف
          </Link>
        </div>
      </div>
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">مسلسل</th>
            <th className="p-4">اسم الخامه</th>
            <th className="p-4">اسم الصنف</th>
            <th className="p-4">اجمالى القطع</th>
            <th className="p-4">اجمالى المبيعات</th>
            <th className="p-4">اجمالى المتبقى</th>
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تعديل</th>
            )}
            {admin.canDelete === 1 && (
              <th className="text-center w-24 p-4">حذف</th>
            )}
          </tr>
        </thead>
        <tbody>
          {variants.map((variant) => {
            totalQuantity += variant.quantity;
            totalSold += variant.sold;
            totalRemaining += variant.quantity - (variant.sold || 0);

            return (
              <tr key={variant.variantId} className="odd:bg-secondaryBg">
                <td className="p-4">{variant.variantId}</td>
                <td className="p-4">
                  <Link
                    to={`/variant/transactions/${variant.variantId}`}
                    className="hover:text-sky-500"
                  >
                    {variant.fabricName}
                  </Link>
                </td>
                <td className="p-4">{variant.variantName}</td>
                <td className="p-4">
                  {(variant.quantity || 0).toLocaleString("en-US")}
                </td>
                <td className="p-4">
                  {(variant.sold || 0).toLocaleString("en-US")}
                </td>
                <td className="p-4">
                  {(
                    (variant.quantity || 0) - (variant.sold || 0)
                  ).toLocaleString("en-US")}
                </td>{" "}
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <Link to={`/variant/form/${variant.variantId}`}>✏</Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() => deleteFabric(variant.variantId)}
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
          <tr>
            <td className="p-4" colSpan={3}></td>
            <td className="p-4">
              {(totalQuantity || 0).toLocaleString("en-US")}
            </td>
            <td className="p-4">{(totalSold || 0).toLocaleString("en-US")}</td>
            <td className="p-4">
              {(totalRemaining || 0).toLocaleString("en-US")}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Variants;
