import { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";

import settings from "../../config";
import { format } from "../../utils";
import Header from "../Header";

const MerchantDetails = () => {
  const [merchantDetails, setMerchantDetails] = useState([]);
  const { merchantId = "" } = useParams();
  const [admin] = useLocalStorage("admin");

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}merchants/details`, {
        merchantId,
      });
      if (response.data.statusCode === 200) {
        setMerchantDetails(response.data.data);
      }
    })();
  }, [merchantId]);

  return (
    <div className="w-full" dir="rtl">
      <Header title="تفاصيل تاجر القماش" />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">مسلسل</th>
            <th className="p-4">اسم القماشه</th>
            <th className="p-4">الوزن</th>
            <th className="p-4">السعر</th>
            <th className="p-4">الاجمالى</th>
            <th className="p-4">تاريخ الشراء</th>
            <th className="p-4">الموسم</th>
            {admin.canEdit === 1 && <th className="text-center w-24">تعديل</th>}
          </tr>
        </thead>
        <tbody>
          {merchantDetails.map((merchantDetail) => {
            return (
              <tr
                key={merchantDetail.fabricTransactionId}
                className={`  odd:bg-secondaryBg`}
              >
                <td className="p-4">{merchantDetail.fabricTransactionId}</td>
                <td className="p-4">{merchantDetail.fabricName}</td>
                <td className="p-4">
                  {(merchantDetail.weight || 0).toLocaleString("en-US")}
                </td>
                <td className="p-4">{format(merchantDetail.price)}</td>
                <td className="p-4">{format(merchantDetail.totalPrice)}</td>
                <td className="p-4">{merchantDetail.transactionDate}</td>
                <td className="p-4">{merchantDetail.seasonName}</td>
                {admin.canEdit === 1 && (
                  <td className="text-center">
                    <div className="flex flex-col items-center w-full cursor-pointer">
                      <div className="font-bold rounded-full w-8">
                        <Link
                          to={`/fabric/transactions/form/${merchantDetail.fabricId}/${merchantDetail.fabricTransactionId}?from=merchant/details/${merchantId}`}
                        >
                          ✏
                        </Link>
                      </div>
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MerchantDetails;
