import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";
import useLocalStorage from "../../hooks/useLocalStorage";
import logo from "../../imgs/albasha-logo.png";
import Input from "../Input/Input";

const Login = () => {
  const navigate = useNavigate();
  const [admin, setAdmin] = useLocalStorage("admin");
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (admin?.adminId && admin?.canEdit !== undefined) {
      navigate("/dashboard");
    }
  }, [admin?.adminId, navigate, admin?.canEdit]);

  return (
    <form
      className="h-full bg-primaryBg text-primaryText"
      dir="rtl"
      onSubmit={async (e) => {
        e.preventDefault();

        const response = await axios.post(
          `${settings.api}admin/login`,
          formData,
        );

        if (response.data.statusCode === 200) {
          setAdmin(response.data.data);
        }
      }}
    >
      <div className="flex flex-col justify-center items-center h-full w-full lg:w-1/3 p-8 m-auto gap-4">
        <img className="w-1/2" src={logo} alt="" />

        <div className="shadow-xl hover:shadow-2xl bg-secondaryBg w-full rounded-xl divide-solid divide-y flex justify-center items-center px-4 border border-primaryText/20">
          <div className="flex flex-col text-sm py-5 gap-3 w-3/4">
            <div className="w-full font-bold text-xl text-center mb-5">
              مرحبا فى اداره مصانع الباشا
            </div>

            <div className="flex space-x-10 w-full" dir="ltr">
              <Input
                dir="ltr"
                label="User Name"
                value={formData.username}
                onChange={onChange}
                required
                name="username"
              />
            </div>
            <div className="flex space-x-10 w-full" dir="ltr">
              <Input
                dir="ltr"
                label="Password"
                value={formData.password}
                name="password"
                onChange={onChange}
                required
                type="password"
              />
            </div>
            <div className="w-full">
              <hr className=" border-primaryText/30" />
            </div>
            <div className="flex flex-row justify-center space-x-10 w-full mt-5">
              <Button
                type="submit"
                text="تسجيل دخول"
                isDisabled={!(formData.username && formData.password)}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Login;
