import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import { ModalContext } from "../../context";
import settings from "../../config";

const Employees = () => {
  const [employees, setEmployees] = useState([]);
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}employees/`);

      if (response.data.statusCode === 200) {
        setEmployees(response.data.data);
      }
    })();
  }, []);

  const toggleStatus = async (employeeId) => {
    const response = await axios.post(`${settings.api}employees/status`, {
      employeeId,
    });

    if (response.data.statusCode === 200) {
      setEmployees(
        employees.map((employee) =>
          employeeId === employee.employeeId
            ? {
                ...employee,
                status: response.data.data ? !employee.status : employee.status,
              }
            : employee,
        ),
      );
    }
  };

  const deleteEmployee = async (employeeId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(`${settings.api}employees/delete`, {
          employeeId,
        });

        if (response.data.statusCode === 200) {
          setEmployees(
            employees.filter((employee) => employeeId !== employee.employeeId),
          );
        }
      },
    });
  };

  return (
    <div className="w-full" dir="rtl">
      <div className="w-full font-bold mb-4 cursor-pointer">
        <Link to="/employee/form">
          <span className="inline-block text-center font-bold text-green-500 bg-green-100 rounded-full w-8">
            +
          </span>{" "}
          اضافه موظف
        </Link>
      </div>
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th>مسلسل</th>
            <th>الاسم</th>
            <th>الوظيفه</th>
            <th className="text-center w-24">الحاله</th>
            <th className="text-center w-24">حذف</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((employee) => {
            return (
              <tr
                key={employee.employeeId}
                className="bg-white odd:bg-gray-100"
              >
                <td>{employee.employeeId}</td>
                <td className="text-sky-500 hover:underline">
                  <Link to={`/employee/form/${employee.employeeId}`}>
                    {employee.name}
                  </Link>
                </td>
                <td>{employee.jobTitle}</td>
                <td>
                  <div
                    className="flex flex-col items-center w-full cursor-pointer"
                    onClick={() => toggleStatus(employee.employeeId)}
                  >
                    <div
                      className={`flex justify-${
                        employee.status ? "end" : "start"
                      } w-1/2 p-1 ${
                        employee.status ? "bg-sky-500" : "bg-gray-500"
                      } rounded-md`}
                    >
                      <div className={`bg-white w-1/4 h-1 rounded-full`}>
                        &nbsp;
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <div
                    className="flex flex-col items-center w-full cursor-pointer"
                    onClick={() => deleteEmployee(employee.employeeId)}
                  >
                    <div className="font-bold text-red-500 bg-red-100 rounded-full w-8">
                      X
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Employees;
