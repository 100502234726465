import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";

import { ModalContext } from "../../context";
import useLocalStorage from "../../hooks/useLocalStorage";
import settings from "../../config";
import Button from "../Button";
import Header from "../Header";

const Inventory = () => {
  const [inventory, setInventory] = useState([]);
  const [admin] = useLocalStorage("admin");
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}inventory/`, {
        type: "fabric",
      });

      if (response.data.statusCode === 200) {
        setInventory(response.data.data);
      }
    })();
  }, []);

  const deleteInventory = async (inventoryId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(`${settings.api}inventory/delete`, {
          inventoryId,
        });

        if (response.data.statusCode === 200) {
          if (response.data.data) {
            setInventory(
              inventory.filter((record) => record.inventoryId !== inventoryId),
            );
          }
        }
      },
    });
  };

  const handleCreate = async () => {
    const response = await axios.post(`${settings.api}inventory/create`, {
      type: "fabric",
    });

    if (response.data.statusCode === 200) {
      setInventory([...inventory, response.data.data[0]]);
    }
  };

  return (
    <div className="w-full" dir="rtl">
      <Header title="جرد القماش" />
      <div className="flex justify-center">
        <Button text="جرد جديد" action={handleCreate} className="w-1/4" />
      </div>
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">تاريخ الجرد</th>
            <th className="text-center w-24 p-4">التفاصيل</th>
            {admin.canDelete === 1 && (
              <th className="text-center w-24 p-4">حذف</th>
            )}
          </tr>
        </thead>
        <tbody>
          {inventory.map((record) => {
            return (
              <tr key={record.inventoryId} className="odd:bg-secondaryBg">
                <td className="p-4">{record.inventoryDate}</td>
                <td className="text-center p-4">
                  <Link to={`/fabric-inventory/${record.inventoryId}`}>📄</Link>
                </td>
                {admin.canDelete === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() => deleteInventory(record.inventoryId)}
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Inventory;
