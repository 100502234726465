import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";
import Input from "../Input/Input";
import Header from "../Header";

const SeasonForm = () => {
  const navigate = useNavigate();
  const { seasonId } = useParams();

  const [formData, setFormData] = useState({
    seasonId,
    seasonName: "",
    seasonYear: "",
  });

  useEffect(() => {
    if (seasonId) {
      (async () => {
        const response = await axios.post(`${settings.api}seasons/details`, {
          seasonId,
        });

        if (response.data.data) {
          setFormData((oldState) => ({
            ...oldState,
            ...response.data.data[0],
          }));
        }
      })();
    }
  }, [seasonId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      dir="rtl"
      onSubmit={async (e) => {
        e.preventDefault();

        const response = await axios.post(
          `${settings.api}seasons/${seasonId ? "update" : "create"}`,
          formData,
        );

        if (response.data.statusCode === 200) {
          navigate(`/seasons`);
        }
      }}
    >
      <div className="w-full">
        <Header title="استماره الموسم" />

        <div className="flex flex-col w-full lg:w-1/2 m-auto gap-4">
          <Input
            label="اسم الموسم"
            name="seasonName"
            value={formData.seasonName}
            onChange={onChange}
          />

          <Input
            label="سنه الموسم"
            name="seasonYear"
            value={formData.seasonYear}
            onChange={onChange}
          />

          <Button
            className="w-1/2 m-auto"
            type="submit"
            text="تسجيل"
            isDisabled={!(formData.seasonName && formData.seasonYear)}
          />
        </div>
      </div>
    </form>
  );
};

export default SeasonForm;
