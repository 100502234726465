import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";
import Header from "../Header";
import Select from "../Select/Select";
import Input from "../Input/Input";

const VariantForm = () => {
  const navigate = useNavigate();
  const { variantId } = useParams();
  const [fabrics, setFabrics] = useState([]);

  const [formData, setFormData] = useState({
    variantId,
    fabricId: "",
    variantName: "",
    variantCode: "",
    finalPrice: "",
    seasonId: "",
  });

  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}seasons/`);

      if (response.data.statusCode === 200) {
        setSeasons(response.data.data);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}fabrics/`);
      setFabrics(response.data.data);
    })();
  }, []);

  useEffect(() => {
    if (variantId) {
      (async () => {
        const response = await axios.post(`${settings.api}variants/view`, {
          variantId,
        });

        if (response.data.data) {
          setFormData((oldState) => ({
            ...oldState,
            ...response.data.data[0],
          }));
        }
      })();
    }
  }, [variantId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      dir="rtl"
      onSubmit={async (e) => {
        e.preventDefault();

        const response = await axios.post(
          `${settings.api}variants/${variantId ? "update" : "create"}`,
          formData,
        );

        if (response.data.statusCode === 200) {
          navigate(`/variants`);
        }
      }}
    >
      <div className="w-full">
        <Header title="استماره الصنف" />

        <div className="flex flex-col w-full lg:w-1/2 m-auto gap-4">
          <Select
            label="اسم الخامه"
            value={formData.fabricId}
            onChange={onChange}
            required
            name="fabricId"
          >
            <option value="">اختر</option>
            {fabrics.map((fabric) => (
              <option key={fabric.fabricId} value={fabric.fabricId}>
                {fabric.fabricName}
              </option>
            ))}
          </Select>

          <Input
            label="اسم الصنف"
            value={formData.variantName}
            onChange={onChange}
            required
            name="variantName"
          />

          <Input
            label="السعر"
            value={formData.finalPrice}
            onChange={onChange}
            required
            name="finalPrice"
          />

          <Select
            label="اسم الموسم"
            value={formData.seasonId}
            onChange={onChange}
            name="seasonId"
          >
            <option value="">اختر</option>
            {seasons.map((season) => (
              <option key={season.seasonId} value={season.seasonId}>
                {season.seasonName} - {season.seasonYear}
              </option>
            ))}
          </Select>

          <Button
            type="submit"
            text="تسجيل"
            isDisabled={!(formData.fabricId && formData.variantName)}
          />
        </div>
      </div>
    </form>
  );
};

export default VariantForm;
