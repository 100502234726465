import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ModalProvider } from "./context";
import Layout from "./components/Layout";
import "./output.css";

function App() {
  return (
    <BrowserRouter>
      <ModalProvider>
        <Routes>
          <Route path="/*" element={<Layout />} />
        </Routes>
      </ModalProvider>
    </BrowserRouter>
  );
}

export default App;
