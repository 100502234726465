import { useEffect, useState } from "react";
import axios from "axios";

import { format } from "../../utils";
import settings from "../../config";
import Header from "../Header";

const types = {
  variant_client_instalments: "مبيعات عملاء",
  fabric_instalments: "مدفوعات قماش",
  accessory_instalments: "مدفوعات اكسسوارات",
  bills: "دفتر اليوميه",
};

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [filter, setFilter] = useState();

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}transactions/`);

      if (response.data.statusCode === 200) {
        setTransactions(response.data.data);
      }
    })();
  }, []);

  const cash = transactions.reduce((acc, value) => {
    if (value.paymentMethod === "نقدى") {
      if (value.type === "variant_client_instalments") {
        return value.amount + acc;
      } else {
        return acc - value.amount;
      }
    } else {
      return acc;
    }
  }, 0);

  const vodafoneCash = transactions.reduce((acc, value) => {
    if (value.paymentMethod === "فودافون كاش") {
      if (value.type === "variant_client_instalments") {
        return value.amount + acc;
      } else {
        return acc - value.amount;
      }
    } else {
      return acc;
    }
  }, 0);

  const etisalatCash = transactions.reduce((acc, value) => {
    if (value.paymentMethod === "اتصالات كاش") {
      if (value.type === "variant_client_instalments") {
        return value.amount + acc;
      } else {
        return acc - value.amount;
      }
    } else {
      return acc;
    }
  }, 0);

  const post = transactions.reduce((acc, value) => {
    if (value.paymentMethod === "بوسطه") {
      if (value.type === "variant_client_instalments") {
        return value.amount + acc;
      } else {
        return acc - value.amount;
      }
    } else {
      return acc;
    }
  }, 0);

  const bank = transactions.reduce((acc, value) => {
    if (value.paymentMethod === "بنك") {
      if (value.type === "variant_client_instalments") {
        return value.amount + acc;
      } else {
        return acc - value.amount;
      }
    } else {
      return acc;
    }
  }, 0);

  const cheque = transactions.reduce((acc, value) => {
    if (value.paymentMethod === "شيك") {
      if (value.type === "variant_client_instalments") {
        return value.amount + acc;
      } else {
        return acc - value.amount;
      }
    } else {
      return acc;
    }
  }, 0);

  return (
    <div className="w-full" dir="rtl">
      <Header title="الحسابات" />

      <div className="flex gap-x-8 gap-y-8 flex-wrap justify-center flex-row-reverse">
        <div
          className="bg-gradient-to-b from-secondaryBg hover:bg-textPrimary/10 p-10 rounded-lg cursor-pointer"
          onClick={() => {
            setFilter("نقدى");
          }}
        >
          <div className="text-center">نقدى</div>
          <div className="text-xl text-center">{format(cash)}</div>
        </div>
        <div
          className="bg-gradient-to-b from-secondaryBg hover:bg-textPrimary/10 p-10 rounded-lg  cursor-pointer"
          onClick={() => {
            setFilter("فودافون كاش");
          }}
        >
          <div className="text-center">فودافون كاش</div>
          <div className="text-xl text-center">{format(vodafoneCash)}</div>
        </div>
        <div
          className="bg-gradient-to-b from-secondaryBg hover:bg-textPrimary/10 p-10 rounded-lg  cursor-pointer"
          onClick={() => {
            setFilter("اتصالات كاش");
          }}
        >
          <div className="text-center">اتصالات كاش</div>
          <div className="text-xl text-center">{format(etisalatCash)}</div>
        </div>
        <div
          className="bg-gradient-to-b from-secondaryBg hover:bg-textPrimary/10 p-10 rounded-lg  cursor-pointer"
          onClick={() => {
            setFilter("بوسطه");
          }}
        >
          <div className="text-center">بوسطه</div>
          <div className="text-xl text-center">{format(post)}</div>
        </div>
        <div
          className="bg-gradient-to-b from-secondaryBg hover:bg-textPrimary/10 p-10 rounded-lg  cursor-pointer"
          onClick={() => {
            setFilter("بنك");
          }}
        >
          <div className="text-center">بنك</div>
          <div className="text-xl text-center">{format(bank)}</div>
        </div>
        <div
          className="bg-gradient-to-b from-secondaryBg hover:bg-textPrimary/10 p-10 rounded-lg  cursor-pointer"
          onClick={() => {
            setFilter("شيك");
          }}
        >
          <div className="text-center">شيك</div>
          <div className="text-xl text-center">{format(cheque)}</div>
        </div>
      </div>
      {filter && (
        <table key={filter} className="w-full table-auto text-right mt-5">
          <thead>
            <tr>
              <th className="p-4">النوع</th>
              <th className="p-4">الاسم</th>
              <th className="p-4">القيمه</th>
              <th className="p-4">تاريخ الدفعه</th>
              <th className="p-4">طريقه الدفع</th>
              <th className="p-4">الموسم</th>
            </tr>
          </thead>
          <tbody>
            {transactions
              .filter((item) => item.paymentMethod === filter)
              .map((transaction, index) => {
                return (
                  <tr
                    key={transaction.paymentMethod + transaction.type + index}
                    className="odd:bg-secondaryBg"
                  >
                    <td className="p-4">{types[transaction.type]}</td>
                    <td className="p-4">{transaction.name}</td>
                    <td className="p-4">{format(transaction.amount)}</td>
                    <td className="p-4">{transaction.instalmentDate}</td>
                    <td className="p-4">{transaction.paymentMethod}</td>
                    <td className="p-4">{transaction.seasonName}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Transactions;
