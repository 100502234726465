const Button = ({
  text,
  isDisabled = false,
  small,
  action,
  type,
  className,
}) => {
  return (
    <button
      className={`cursor-pointer w-full font-bold p-4 rounded-3xl drop-shadow bg-primaryText/20 hover:bg-primaryText/40 ${className}`}
      type={type}
      disabled={isDisabled}
      onClick={action}
    >
      {text}
    </button>
  );
};

export default Button;
