import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Header from "../Header";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ModalContext } from "../../context";
import settings from "../../config";
import { format } from "../../utils";

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [admin] = useLocalStorage("admin");
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}clients/`);
      if (response.data.statusCode === 200) {
        setClients(response.data.data);
      }
    })();
  }, []);

  const deleteClient = async (clientId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(`${settings.api}clients/delete`, {
          clientId,
        });

        if (response.data.statusCode === 200) {
          setClients(clients.filter((client) => clientId !== client.clientId));
        }
      },
    });
  };

  let remainingSum = 0;
  let totalSum = 0;
  let totalSumAfterDiscount = 0;
  let paid = 0;

  return (
    <div className="w-full" dir="rtl">
      <Header title="العملاء" text="اضافه عميل" link="/client/form" />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">اسم العميل</th>
            <th className="p-4">المتبقى</th>
            <th className="p-4">المبلغ الاجمالى</th>
            <th className="p-4">المبلغ بعد الخصم</th>
            <th className="p-4">المدفوع</th>
            
            <th className="text-center w-24 p-4">الفواتير</th>
            <th className="text-center w-24 p-4">الدفعات</th>
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تعديل</th>
            )}
            {admin.canDelete === 1 && (
              <th className="text-center w-24 p-4">حذف</th>
            )}
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => {
            const finalPrice =
              client.finalPrice - client.percentage - client.perPiece;

            remainingSum += finalPrice - client.totalPaid;
            totalSum += client.finalPrice;
            totalSumAfterDiscount += finalPrice;
            paid += client.totalPaid;

            return (
              <tr key={client.clientId} className="odd:bg-secondaryBg">
                <td className="p-4">
                  <Link
                    to={`/client/details/${client.clientId}`}
                    className="hover:text-sky-500"
                  >
                    {client.clientName}
                  </Link>
                </td>
                <td className="p-4">
                  {format(finalPrice - client.totalPaid || 0)}
                </td>
                <td className="p-4">{format(client.finalPrice || 0)}</td>
                <td className="p-4">{format(finalPrice || 0)}</td>
                <td className="p-4">{format(client.totalPaid || 0)}</td>
                
                <td className="text-center p-4">
                  <Link to={`/client/invoices/${client.clientId}`}>💰</Link>
                </td>
                <td className="text-center p-4">
                  <div className="flex flex-col items-center w-full cursor-pointer">
                    <div className="font-bold rounded-full w-8">
                      <Link to={`/variant/instalments/${client.clientId}`}>
                        💵
                      </Link>
                    </div>
                  </div>
                </td>
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <Link to={`/client/form/${client.clientId}`}>✏</Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() => deleteClient(client.clientId)}
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
          <tr>
            <td className="text-rose-500 p-4">اجمالى المتبقى</td>
            <td className="text-rose-500 p-4">{format(remainingSum)}</td>
            <td className="text-rose-500 p-4">{format(totalSum)}</td>
            <td className="text-rose-500 p-4">
              {format(totalSumAfterDiscount)}
            </td>
            <td className="text-rose-500 p-4">{format(paid)}</td>
            <td colSpan={4}></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Clients;
