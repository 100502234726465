import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import Header from "../Header";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ModalContext } from "../../context";

import settings from "../../config";
import { format } from "../../utils";

const AccessoryInstalments = () => {
  const [accessoryInstalments, setAccessoryInstalments] = useState([]);
  const { accessoriesMerchantId } = useParams();
  const [admin] = useLocalStorage("admin");
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${settings.api}accessory/instalments`,
        {
          accessoriesMerchantId,
        },
      );

      if (response.data.statusCode === 200) {
        setAccessoryInstalments(response.data.data);
      }
    })();
  }, [accessoriesMerchantId]);

  const deleteAccessoryInstalment = async (accessoryInstalmentId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}accessory/delete-instalment`,
          {
            accessoryInstalmentId,
          },
        );

        if (response.data.statusCode === 200) {
          setAccessoryInstalments(
            accessoryInstalments.filter(
              (accessoryInstalment) =>
                accessoryInstalmentId !==
                accessoryInstalment.accessoryInstalmentId,
            ),
          );
        }
      },
    });
  };

  let totalPaid = 0;

  return (
    <div className="w-full" dir="rtl">
      <Header
        title="دفعات تجار الاكسسوارات"
        text="اضافه دفعه"
        link={`/accessory/instalments/form/${accessoriesMerchantId}`}
      />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">اسم تاجر الاكسسوار</th>
            <th className="p-4">المبلغ الاجمالى</th>
            <th className="p-4">قيمه الدفعه</th>
            <th className="p-4">خصم</th>
            <th className="p-4">المبلغ المتبقى</th>
            <th className="p-4">تاريخ الدفعه</th>
            <th className="p-4">الموسم</th>
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تعديل</th>
            )}
            {admin.canDelete === 1 && (
              <th className="text-center w-24 p-4">حذف</th>
            )}
          </tr>
        </thead>
        <tbody>
          {accessoryInstalments.map((accessoryInstalment) => {
            totalPaid +=
              accessoryInstalment.totalPaid + accessoryInstalment.discount;

            return (
              <tr
                key={accessoryInstalment.accessoryInstalmentId}
                className="odd:bg-secondaryBg"
              >
                <td className="p-4">
                  {accessoryInstalment.accessoriesMerchantName}
                </td>
                <td className="p-4">
                  {format(accessoryInstalment.amount || 0)}
                </td>
                <td className="p-4">
                  {format(accessoryInstalment.totalPaid || 0)}
                </td>
                <td className="p-4">
                  {format(accessoryInstalment.discount || 0)}
                </td>
                <td className="p-4">
                  {format(accessoryInstalment.amount - totalPaid || 0)}
                </td>
                <td className="p-4">{accessoryInstalment.instalmentDate}</td>
                <td className="p-4">{accessoryInstalment.seasonName}</td>
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <Link
                      to={`/accessory/instalments/form/${accessoriesMerchantId}/${accessoryInstalment.accessoryInstalmentId}`}
                    >
                      ✏
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() =>
                        deleteAccessoryInstalment(
                          accessoryInstalment.accessoryInstalmentId,
                        )
                      }
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AccessoryInstalments;
