import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Header from "../Header";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ModalContext } from "../../context";

import settings from "../../config";
import { format } from "../../utils";

const Accessories = () => {
  const [accessories, seAccessories] = useState([]);
  const [admin] = useLocalStorage("admin");
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}accessories/`);

      if (response.data.statusCode === 200) {
        seAccessories(response.data.data);
      }
    })();
  }, []);

  const deleteAccesory = async (accessoryId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(`${settings.api}accessories/delete`, {
          accessoryId,
        });

        if (response.data.statusCode === 200) {
          seAccessories(
            accessories.filter(
              (accessory) => accessoryId !== accessory.accessoryId,
            ),
          );
        }
      },
    });
  };

  return (
    <div className="w-full" dir="rtl">
      <Header title="الاكسسوارات" text="اضافه اكسسوار" link="/accessory/form" />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">اسم الاكسسوار</th>
            <th className="p-4">السعر الاجمالى</th>
            <th className="p-4">العددي الاجمالى</th>
            <th className="p-4">العدد المتاح</th>
            <th className="text-center w-24 p-4">تركيب</th>
            <th className="text-center w-24 p-4">معاملات</th>
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تعديل</th>
            )}
            {admin.canDelete === 1 && (
              <th className="text-center w-24 p-4">حذف</th>
            )}
          </tr>
        </thead>
        <tbody>
          {accessories.map((accessory) => {
            return (
              <tr key={accessory.accessoryId} className="odd:bg-secondaryBg">
                <td className="p-4">{accessory.accessoryName}</td>
                <td className="p-4">{format(accessory.price || 0)}</td>
                <td className="p-4">{accessory.weight || 0}</td>
                <td className="p-4">
                  {accessory.weight - accessory.usedWeight || 0}
                </td>
                <td className="text-center p-4">
                  <div className="flex flex-col items-center w-full cursor-pointer">
                    <div className="font-bold rounded-full">
                      <Link to={`/accessory/variants/${accessory.accessoryId}`}>
                        👕
                      </Link>
                    </div>
                  </div>
                </td>
                <td className="text-center p-4">
                  <Link
                    className="cursor-pointer"
                    to={`/accessory/transactions/${accessory.accessoryId}`}
                  >
                    💵
                  </Link>
                </td>
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <Link to={`/accessory/form/${accessory.accessoryId}`}>
                      ✏
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() => deleteAccesory(accessory.accessoryId)}
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Accessories;
