import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";
import Header from "../Header";
import Select from "../Select/Select";
import Input from "../Input/Input";

const VariantForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { fabricTransactionVariantId = "", variantClientId = "" } = useParams();
  const [clients, setClients] = useState([]);
  const [variantTransactionPrice, setVariantTransactionPrice] = useState({});
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    clientId: "",
    variantClientId,
    fabricTransactionVariantId,
    quantity: 0,
    clientPrice: 0,
    processingDate: "",
    discount: 0,
    discountType: 0,
    seasonIn: "",
  });

  const price = variantTransactionPrice?.price || 0;

  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}seasons/`);

      if (response.data.statusCode === 200) {
        setSeasons(response.data.data);
      }
    })();
  }, []);

  useEffect(() => {
      (async () => {
        const response = await axios.post(
          `${settings.api}fabric/variant-transaction-price`,
          {
            fabricTransactionVariantId,
          },
        );

        if (response.data.statusCode === 200) {
          setVariantTransactionPrice(response.data.data);
          setLoading(false);
        }
      })();
  }, [fabricTransactionVariantId]);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}clients/`);
      setClients(response.data.data);
    })();
  }, []);

  useEffect(() => {
    if (variantClientId && price !== 0) {
      (async () => {
        const response = await axios.post(
          `${settings.api}variant-client/view`,
          {
            variantClientId,
          },
        );

        if (response.data.data) {
          setFormData(response.data.data[0])
        }
      })();
    }
  }, [variantClientId, price]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      dir="rtl"
      onSubmit={async (e) => {
        e.preventDefault();

        const response = await axios.post(
          `${settings.api}variant-client/${
            variantClientId ? "update" : "create"
          }`,
          formData,
        );

        if (response.data.statusCode === 200) {
          if (searchParams.get("from")) {
            navigate(`/${searchParams.get("from")}`);
          } else {
            navigate(`/variant/sell/${fabricTransactionVariantId}`);
          }
        }
      }}
    >
      <div className="w-full">
        <Header title="استماره مبيعات" />

        <div className="flex flex-col w-full lg:w-1/2 m-auto gap-4">
          <Select
            label="اسم العميل"
            value={formData.clientId}
            onChange={onChange}
            required
            name="clientId"
          >
            <option value="">اختر</option>
            {clients.map((client) => (
              <option key={client.clientId} value={client.clientId}>
                {client.clientName} - {client.clientCode}
              </option>
            ))}
          </Select>

          <Input
            label="الكميه"
            value={formData.quantity}
            onChange={onChange}
            required
            name="quantity"
          />

          <Input
            label="سعر البيع"
            value={formData.clientPrice}
            onChange={onChange}
            required
            name="clientPrice"
          />

          {formData.clientPrice < price && (
            <div className="text-rose-500">
              خساره {price - formData.clientPrice}
            </div>
          )}
          {formData.clientPrice > price && (
            <div className="text-green-500">
              ربح {formData.clientPrice - price}
            </div>
          )}

          <Select
            label="نوع الخصم"
            value={formData.discountType}
            onChange={onChange}
            name="discountType"
          >
            <option value="">اختر</option>
            <option key="percentage" value={1}>
              نسبه مئويه
            </option>
            <option key="amount" value={2}>
              خصم بالجنيه
            </option>
          </Select>

          <Input
            label="الخصم"
            value={formData.discount}
            onChange={onChange}
            name="discount"
          />

          <Input
            label="التاريخ"
            value={formData.processingDate}
            onChange={onChange}
            required
            name="processingDate"
            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
            type="date"
          />
          <Select
            label="اسم الموسم"
            value={formData.seasonId}
            onChange={onChange}
            name="seasonId"
          >
            <option value="">اختر</option>
            {seasons.map((season) => (
              <option key={season.seasonId} value={season.seasonId}>
                {season.seasonName} - {season.seasonYear}
              </option>
            ))}
          </Select>

          <Button
            type="submit"
            text="تسجيل"
            isDisabled={
              !(
                formData.fabricTransactionVariantId &&
                formData.clientId &&
                formData.processingDate &&
                formData.clientPrice &&
                formData.quantity
              )
            }
          />
        </div>
      </div>
    </form>
  );
};

export default VariantForm;
