import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import settings from "../../config";
import Header from "../Header";

const VariantTransactions = () => {
  const [variantTransactions, setVariantTransactions] = useState([]);
  const { variantId } = useParams();

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}variant/transactions`, {
        variantId,
      });

      if (response.data.statusCode === 200) {
        setVariantTransactions(response.data.data);
      }
    })();
  }, [variantId]);

  return (
    <div className="w-full" dir="rtl">
      <Header title="معاملات الاصناف" />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">كود الصنف</th>
            <th className="p-4">اسم الصنف</th>
            <th className="p-4">عدد القطع</th>
            <th className="p-4">مبيعات</th>
            <th className="p-4">متاح</th>
            <th className="p-4">الموسم</th>
          </tr>
        </thead>
        <tbody>
          {variantTransactions.map((variantTransaction) => {
            return (
              <tr
                key={variantTransaction.fabricTransactionVariantId}
                className="odd:bg-secondaryBg"
              >
                <td className="p-4">
                  <Link
                    to={`/variant/transactions/${variantId}/
                    ${variantTransaction.variantCode}`}
                  >
                    {variantTransaction.variantCode}
                  </Link>
                </td>
                <td className="p-4">{variantTransaction.variantName}</td>
                <td className="p-4">
                  {(variantTransaction.quantity || 0).toLocaleString("en-US")}
                </td>
                <td className="p-4">
                  {(variantTransaction.sold || 0).toLocaleString("en-US")}
                </td>
                <td className="p-4">
                  {(
                    (variantTransaction.quantity || 0) -
                    (variantTransaction.sold || 0)
                  ).toLocaleString("en-US")}
                </td>

                <td className="p-4">{variantTransaction.seasonName}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default VariantTransactions;
