const Input = ({ label, name, value, onChange, ...rest }) => {
  return (
    <label className="w-full">
      {label}
      <input
        placeholder={label}
        value={value}
        onChange={onChange}
        className="w-full border text-primaryBg rounded-3xl my-2 p-4 invalid:border-pink-500 invalid:text-pink-600
        focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
        name={name}
        {...rest}
      />
    </label>
  );
};

export default Input;
