import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import Header from "../Header";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ModalContext } from "../../context";

import settings from "../../config";
import { format } from "../../utils";

const FabricVariantTransactions = () => {
  const [fabricVariants, setFabricInstalments] = useState([]);
  const { fabricTransactionId } = useParams();
  const [admin] = useLocalStorage("admin");
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${settings.api}fabric/variant-transactions`,
        {
          fabricTransactionId,
        },
      );

      if (response.data.statusCode === 200) {
        setFabricInstalments(response.data.data);
      }
    })();
  }, [fabricTransactionId]);

  const deleteProductInstalment = async (fabricTransactionVariantId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}fabric/delete-variant-transaction`,
          {
            fabricTransactionVariantId,
          },
        );

        if (response.data.statusCode === 200) {
          setFabricInstalments(
            fabricVariants.filter(
              (fabricVariant) =>
                fabricTransactionVariantId !==
                fabricVariant.fabricTransactionVariantId,
            ),
          );
        }
      },
    });
  };

  return (
    <div className="w-full" dir="rtl">
      <Header
        title="تركيب القماش"
        text="اضافه منتجات"
        link={`/fabric/variant/form/${fabricTransactionId}`}
      />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">الحاله</th>
            <th className="p-4">مسلسل</th>
            <th className="p-4">كود المنتج</th>
            <th className="p-4">اسم المنتج</th>
            <th className="p-4">نوع المنتج</th>
            <th className="p-4">العدد</th>
            <th className="p-4">السعر بالقطعه</th>
            <th className="p-4">السعر الاجمالى</th>
            <th className="p-4">تاريخ الدفعه</th>
            <th className="p-4">الموسم</th>
            <th className="text-center w-24 p-4">تركيب</th>
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تعديل</th>
            )}
            {admin.canDelete === 1 && (
              <th className="text-center w-24 p-4">حذف</th>
            )}
          </tr>
        </thead>
        <tbody>
          {fabricVariants.map((fabricVariant) => {
            return (
              <tr
                key={fabricVariant.fabricTransactionVariantId}
                className="odd:bg-secondaryBg"
              >
                <td className="text-center p-4">
                  <div className="w-full flex flex-row items-center justify-center">
                    {fabricVariant.status === 1 && (
                      <div
                        className="items-center bg-rose-500 w-5 h-5 rounded-full"
                        alt="دوره تشغيل"
                        title="دوره تشغيل"
                      />
                    )}
                    {fabricVariant.status === 2 && (
                      <div
                        className="items-center bg-green-500 w-5 h-5 rounded-full"
                        alt="فى المخزن"
                        title="فى المخزن"
                      />
                    )}
                  </div>
                </td>
                <td className="p-4">
                  {fabricVariant.fabricTransactionVariantId}
                </td>
                <td className="p-4">{fabricVariant.variantCode}</td>
                <td className="p-4">{fabricVariant.variantName}</td>
                <td className="p-4">{fabricVariant.variantType}</td>
                <td className="p-4">
                  {(fabricVariant.quantity || 0).toLocaleString("en-US")}
                </td>
                <td className="p-4">{format(fabricVariant.price || 0)}</td>
                <td className="p-4">
                  {format(fabricVariant.price * fabricVariant.quantity || 0)}
                </td>
                <td className="p-4">{fabricVariant.processingDate}</td>
                <td className="p-4">{fabricVariant.seasonName}</td>
                <td className="text-center p-4">
                  <div className="flex flex-col items-center w-full cursor-pointer">
                    <div className="font-bold rounded-full w-8">
                      <Link
                        to={`/variant/operations/${fabricVariant.fabricTransactionVariantId}`}
                      >
                        👕
                      </Link>
                    </div>
                  </div>
                </td>
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <Link
                      to={`/fabric/variant/form/${fabricTransactionId}/${fabricVariant.fabricTransactionVariantId}`}
                      className="flex flex-col items-center w-full cursor-pointer"
                    >
                      ✏
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() =>
                        deleteProductInstalment(
                          fabricVariant.fabricTransactionVariantId,
                        )
                      }
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FabricVariantTransactions;
