import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import settings from "../../config";
import Button from "../Button";
import { useParams } from "react-router-dom";
import Header from "../Header";
import Input from "../Input/Input";

const Inventory = () => {
  const [variants, setVariants] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [operations, setOperations] = useState([]);
  const [updated, setUpdated] = useState(false);

  const { inventoryId } = useParams();

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}variants/with-code`);

      if (response.data.statusCode === 200) {
        setVariants(response.data.data);
      }

      const operationResponse = await axios.post(
        `${settings.api}inventory-operation/`,
        {
          inventoryId,
        },
      );

      if (operationResponse.data.statusCode === 200) {
        const operations = operationResponse.data.data;
        setOperations(operations);
      }
    })();
  }, [inventoryId]);

  useEffect(() => {
    if (operations.length && !updated) {
      const updatedFabrics = variants.map((variant) => {
        const operation = operations.find(
          (operation) =>
            operation.itemId === variant.fabricTransactionVariantId,
        );

        if (operation) {
          return {
            ...variant,
            ...operation,
          };
        } else {
          return variant;
        }
      });

      setVariants(updatedFabrics);
      setUpdated(true);
    }
  }, [variants, operations, operations.length, updated]);

  const onChange = (fabricTransactionVariantId, value) => {
    setVariants(
      variants.map((variant) =>
        variant.fabricTransactionVariantId === fabricTransactionVariantId
          ? {
              ...variant,
              actualQuantity: +value,
            }
          : variant,
      ),
    );
  };

  const onSubmit = useCallback(async () => {
    const payload = {
      inventoryId,
      operations: variants.map((variant) => ({
        itemId: variant.fabricTransactionVariantId,
        actualQuantity: variant.actualQuantity,
        quantity: variant.quantity - variant.sold || 0,
      })),
    };

    const response = await axios.post(
      `${settings.api}inventory-operation/create`,
      payload,
    );

    if (response.data.statusCode === 200) {
      setIsSubmitted(true);
    }
  }, [variants, inventoryId]);

  return (
    <div className="w-full" dir="rtl">
      <Header title="تفاصيل جرد" />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">اسم خامه القماش</th>
            <th className="p-4 w-40">الوزن المتاح</th>
            <th className="p-4 w-40">الوزن داخل المخزن</th>
          </tr>
        </thead>
        <tbody>
          {variants.map((variant) => {
            return (
              <tr
                key={variant.fabricTransactionVariantId}
                className="odd:bg-secondaryBg"
              >
                <td className="p-4">
                  {variant.variantCode} - {variant.variantName}
                </td>

                <td className={`${!isSubmitted && "blur-md"} p-4`}>
                  {(
                    (variant.quantity || 0) - (variant.sold || 0)
                  ).toLocaleString("en-US")}
                </td>
                <td className="p-4">
                  <Input
                    onChange={(e) =>
                      onChange(
                        variant.fabricTransactionVariantId,
                        e.target.value,
                      )
                    }
                    value={variant.actualQuantity || 0}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-center">
        <Button className="w-1/2 my-5" text="جرد" action={onSubmit} />
      </div>
    </div>
  );
};

export default Inventory;
