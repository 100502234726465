import { Link, useLocation, useNavigate } from "react-router-dom";
import assetsIcon from "../../imgs/assets.png";
import billIcon from "../../imgs/bill.png";
import merchantIcon from "../../imgs/merchant.webp";
import fabricIcon from "../../imgs/fabric.png";
import accessoriesIcon from "../../imgs/accessories.png";
import workerIcon from "../../imgs/worker.png";
import tshirtIcon from "../../imgs/t-shirt.svg";
import clientIcon from "../../imgs/client.png";
import moneyBagIcon from "../../imgs/money-bag.svg";
import homePageIcon from "../../imgs/homepage.svg";
import InventoryIcon from "../../imgs/inventory.png";
import logoutIcon from "../../imgs/logout.png";
import useLocalStorage from "../../hooks/useLocalStorage";
import logo from "../../imgs/albasha-logo.png";
import { useEffect } from "react";

const Item = ({ path, text, action, focused }) => {
  const focusedClass = focused
    ? `bg-gradient-to-l from-primaryBg p-4 rounded-3xl`
    : "";

  return (
    <Link
      to={path}
      className={`${focusedClass} flex justify-end text-center items-center p-4 text-primary hover:bg-primary hover:text-textPrimary w-auto whitespace-nowrap`}
      onClick={action}
    >
      {text}
    </Link>
  );
};

const NavBar = ({ setIsNavBarOpen, isNavBarOpen }) => {
  const [admin, setAdmin] = useLocalStorage("admin");
  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    if (!admin?.adminId || admin?.canEdit === undefined) {
      setAdmin({});
      navigate("/");
    }
  }, [admin?.adminId, navigate, setAdmin, admin?.canEdit]);

  return (
    <>
      <div
        className={`absolute top-0 bottom-0 left-0 right-0 min-h-full lg:relative print:hidden flex flex-col p-4 bg-secondaryBg border border-primaryText/20 lg:w-96 overflow-auto ${
          isNavBarOpen ? "block" : "hidden lg:block"
        }`}
      >
          <img src={logo} alt="albasha logo" className="my-4 object-cover w-1/2 lg:w-full justify-center self-center" />        
        <Item
          action={() => setIsNavBarOpen(false)}
          path="/dashboard"
          Icon={homePageIcon}
          text="الرئيسيه"
          focused={/dashboard/.test(location.pathname)}
        />
        <Item
          action={() => setIsNavBarOpen(false)}
          path="/seasons"
          Icon={homePageIcon}
          text="المواسم"
          focused={
            /seasons/.test(location.pathname) ||
            /season\/form/.test(location.pathname)
          }
        />
        <Item
          action={() => setIsNavBarOpen(false)}
          path="/transactions"
          Icon={moneyBagIcon}
          text="الحسابات"
          focused={/^\/transactions/.test(location.pathname)}
        />
        <Item
          action={() => setIsNavBarOpen(false)}
          path="/assets"
          Icon={assetsIcon}
          text="الاصول"
          focused={
            /^\/assets/.test(location.pathname) ||
            /^\/asset\/form/.test(location.pathname) ||
            /^\/asset\/details/.test(location.pathname)
          }
        />
        <Item
          action={() => setIsNavBarOpen(false)}
          path="/bill-categories"
          Icon={billIcon}
          text="المدفوعات"
          focused={
            /bill-category/.test(location.pathname) ||
            /bill-categories/.test(location.pathname)
          }
        />
        <Item
          action={() => setIsNavBarOpen(false)}
          path="/merchants"
          Icon={merchantIcon}
          text="تجار القماش"
          focused={
            /fabric\/instalments/.test(location.pathname) ||
            /\/merchant/.test(location.pathname) ||
            /\/merchants/.test(location.pathname)
          }
        />
        <Item
          action={() => setIsNavBarOpen(false)}
          path="/fabrics"
          Icon={fabricIcon}
          text="القماش"
          focused={/fabrics?(?!(-inventory|\/instalments))|variant\/operations/.test(
            location.pathname,
          )}
        />
        <Item
          action={() => setIsNavBarOpen(false)}
          path="/fabric-inventory"
          Icon={InventoryIcon}
          text="جرد القماش"
          focused={/fabric-inventory/.test(location.pathname)}
        />
        <Item
          action={() => setIsNavBarOpen(false)}
          path="/production-assets"
          Icon={workerIcon}
          text="متطلبات الانتاج"
          focused={/production-assets?/.test(location.pathname)}
        />
        <Item
          action={() => setIsNavBarOpen(false)}
          path="/accessories-merchants"
          Icon={merchantIcon}
          text="تجار الاكسسوارات"
          focused={/\/accessories-merchants?|accessory\/instalments/.test(
            location.pathname,
          )}
        />
        <Item
          action={() => setIsNavBarOpen(false)}
          path="/accessories"
          Icon={accessoriesIcon}
          text="الاكسسوارات"
          focused={
            /\/accessories(?!-inventory)(?!-merchants?)/.test(
              location.pathname,
            ) || /\/accessory\/(?!instalments)/.test(location.pathname)
          }
        />
        <Item
          action={() => setIsNavBarOpen(false)}
          path="/accessories-inventory"
          Icon={InventoryIcon}
          text="جرد الاكسسوارات"
          focused={/\/accessories-inventory/.test(location.pathname)}
        />
        <Item
          action={() => setIsNavBarOpen(false)}
          path="/variants"
          Icon={tshirtIcon}
          text="الاصناف"
          focused={
            /^\/variant(?!s?-inventory|\/instalments|\/operations)/.test(
              location.pathname,
            ) || /^\/variant\/form/.test(location.pathname)
          }
        />
        <Item
          action={() => setIsNavBarOpen(false)}
          path="/variants-inventory"
          Icon={InventoryIcon}
          text="جرد الاصناف"
          focused={/\/variants-inventory/.test(location.pathname)}
        />
        <Item
          action={() => setIsNavBarOpen(false)}
          path="/clients"
          Icon={clientIcon}
          text="العملاء"
          focused={/clients?|variant\/instalments/.test(location.pathname)}
        />
        <Item
          action={() => setIsNavBarOpen(false)}
          path="/discounts"
          Icon={homePageIcon}
          text="خصم نهايه الموسم"
          focused={
            /discounts/.test(location.pathname) ||
            /discount\/form/.test(location.pathname)
          }
        />
        <Item
          path="#"
          Icon={logoutIcon}
          text="تسجيل خروج"
          action={() => {
            setAdmin({});
            navigate(`/`);
          }}
        />
      </div>
      <div className="absolute lg:hidden text-lg h-20 w-12 flex justify-between items-center bg-textPrimary text-primary">
        <div
          className={`${
            (isNavBarOpen && "hidden") || ""
          } flex flex-col justify-between w-12 h-10 p-3 cursor-pointer`}
          onClick={() => setIsNavBarOpen(!isNavBarOpen)}
        >
          <div className="border border-primary"></div>
          <div className="border border-primary"></div>
          <div className="border border-primary"></div>
        </div>
        <div
          className={`${
            (!isNavBarOpen && "hidden") || ""
          } flex flex-col justify-between w-12 h-10 p-3 cursor-pointer text-primary text-2xl`}
          onClick={() => setIsNavBarOpen(!isNavBarOpen)}
        >
          &#x2715;
        </div>
      </div>
    </>
  );
};

export default NavBar;
