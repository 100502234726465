import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";
import Header from "../Header";
import Input from "../Input/Input";
import Select from "../Select/Select";

const VariantInstalmentForm = () => {
  const navigate = useNavigate();
  const { clientId = "", variantClientInstalmentId = "" } = useParams();

  const [formData, setFormData] = useState({
    clientId,
    variantClientInstalmentId,
    amount: "",
    discount: 0,
    instalmentDate: "",
    paymentMethod: "",
    seasonId: "",
  });

  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}seasons/`);

      if (response.data.statusCode === 200) {
        setSeasons(response.data.data);
      }
    })();
  }, []);

  useEffect(() => {
    if (variantClientInstalmentId) {
      (async () => {
        const response = await axios.post(
          `${settings.api}variant-client/view-instalment`,
          {
            variantClientInstalmentId,
          },
        );

        if (response.data.data) {
          setFormData((oldState) => ({
            ...oldState,
            ...response.data.data[0],
          }));
        }
      })();
    }
  }, [variantClientInstalmentId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      dir="rtl"
      onSubmit={async (e) => {
        e.preventDefault();

        const response = await axios.post(
          `${settings.api}variant-client/${
            variantClientInstalmentId
              ? "update-instalment"
              : "create-instalment"
          }`,
          formData,
        );

        if (response.data.statusCode === 200) {
          navigate(`/variant/instalments/${clientId}`);
        }
      }}
    >
      <div className="w-full">
        <Header title="استماره مبيعات" />

        <div className="flex flex-col w-full lg:w-1/2 m-auto gap-4">
          <Input
            label="الدفعه"
            value={formData.amount}
            onChange={onChange}
            required
            name="amount"
          />

          <Input
            label="الخصم"
            value={formData.discount}
            name="discount"
            onChange={onChange}
            required
          />

          <Input
            label="التاريخ"
            value={formData.instalmentDate}
            onChange={onChange}
            required
            name="instalmentDate"
            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
            type="date"
          />

          <Select
            label="طريقه الدفع"
            value={formData.paymentMethod}
            onChange={onChange}
            required
            name="paymentMethod"
          >
            <option value="">اختر</option>
            <option value="نقدى">نقدى</option>
            <option value="فودافون كاش">فودافون كاش</option>
            <option value="اتصالات كاش">اتصالات كاش</option>
            <option value="بوسطه">بوسطه</option>
            <option value="بنك">بنك</option>
            <option value="شيك">شيك</option>
          </Select>

          <Select
            label="اسم الموسم"
            value={formData.seasonId}
            onChange={onChange}
            name="seasonId"
          >
            <option value="">اختر</option>
            {seasons.map((season) => (
              <option key={season.seasonId} value={season.seasonId}>
                {season.seasonName} - {season.seasonYear}
              </option>
            ))}
          </Select>

          <Button
            type="submit"
            text="تسجيل"
            isDisabled={
              !(
                formData.paymentMethod &&
                formData.instalmentDate &&
                formData.amount
              )
            }
          />
        </div>
      </div>
    </form>
  );
};

export default VariantInstalmentForm;
