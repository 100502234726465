const defaultTheme = require("tailwindcss/defaultTheme");
const colors = require("tailwindcss/colors");

const theme = {
  extend: {
    colors: {
      primaryBg: colors.slate[900],
      primaryText: colors.gray[100],
      secondaryBg: colors.slate[800],
      error: colors.pink[500],
    },
    fontFamily: {
      sans: ["Roboto", "Poppins", ...defaultTheme.fontFamily.sans],
    },
    gridTemplateRows: {
      // Simple 50 column grid
      50: "repeat(50, minmax(0, 1fr))",
    },
    gridTemplateColumns: {
      // Simple 50 column grid
      50: "repeat(50, minmax(0, 1fr))",
    },
  },
};

const settings = {
  api:
    (process.env.NODE_ENV === "production" &&
      "https://albasha-api.gtahomespa.com/") ||
    "http://localhost:4000/",
  theme,
};

module.exports = settings;
