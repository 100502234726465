import { Link } from "react-router-dom";

const Header = ({ title, text, link }) => {
  return (
    <div className="mb-8">
      {title && <div className="text-2xl text-right my-4">{title}</div>}
      {link && (
        <div className="flex w-full font-bold cursor-pointer">
          <Link to={link} className=" bg-secondaryBg rounded-xl text-white p-3">
            + {text}
          </Link>
        </div>
      )}
      <hr className="my-5 border border-primaryText/20" />
    </div>
  );
};

export default Header;
