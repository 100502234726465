import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Header from "../Header";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ModalContext } from "../../context";

import settings from "../../config";
import { format } from "../../utils";

const Fabrics = () => {
  const [fabrics, seFabrics] = useState([]);
  const [admin] = useLocalStorage("admin");
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}fabrics/`);

      if (response.data.statusCode === 200) {
        seFabrics(response.data.data);
      }
    })();
  }, []);

  const deleteFabric = async (fabricId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(`${settings.api}fabrics/delete`, {
          fabricId,
        });

        if (response.data.statusCode === 200) {
          seFabrics(fabrics.filter((fabric) => fabricId !== fabric.fabricId));
        }
      },
    });
  };

  return (
    <div className="w-full" dir="rtl">
      <Header title="القماش" text="اضافه خامه قماش" link="/fabric/form" />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">مسلسل</th>
            <th className="p-4">اسم خامه القماش</th>
            <th className="p-4">السعر الاجمالى</th>
            <th className="p-4">الوزن الاجمالى</th>
            <th className="p-4">الوزن المتاح</th>
            <th className="text-center w-24 p-4">شراء</th>
            <th className="text-center w-24 p-4">قص</th>
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تعديل</th>
            )}
            {admin.canDelete === 1 && (
              <th className="text-center w-24 p-4">حذف</th>
            )}
          </tr>
        </thead>
        <tbody>
          {fabrics.map((fabric) => {
            return (
              <tr key={fabric.fabricId} className="odd:bg-secondaryBg">
                <td className="p-4">{fabric.fabricId}</td>
                <td className="p-4">{fabric.fabricName}</td>
                <td className="p-4">{format(fabric.price || 0)}</td>
                <td className="p-4">
                  {(fabric.totalWeight || 0).toLocaleString("en-US")}
                </td>
                <td className="p-4">
                  {(fabric.weight || 0).toLocaleString("en-US")}
                </td>
                <td className="text-center p-4">
                  <Link
                    className="cursor-pointer"
                    to={`/fabric/transactions/${fabric.fabricId}`}
                  >
                    💰
                  </Link>
                </td>
                <td className="text-center p-4">
                  <Link
                    className="cursor-pointer"
                    to={`/fabric/cuts/${fabric.fabricId}`}
                  >
                    ✂️
                  </Link>
                </td>
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <Link to={`/fabric/form/${fabric.fabricId}`}>✏</Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() => deleteFabric(fabric.fabricId)}
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Fabrics;
