import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Header from "../Header";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ModalContext } from "../../context";
import settings from "../../config";

const BillCategories = () => {
  const [billCategories, seBillCategories] = useState([]);
  const [admin] = useLocalStorage("admin");
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}bill-categories/`);

      if (response.data.statusCode === 200) {
        seBillCategories(response.data.data);
      }
    })();
  }, []);

  const deleteBillCategory = async (billCategoryId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}bill-categories/delete`,
          {
            billCategoryId,
          },
        );

        if (response.data.statusCode === 200) {
          seBillCategories(
            billCategories.filter(
              (billCategory) => billCategoryId !== billCategory.billCategoryId,
            ),
          );
        }
      },
    });
  };

  return (
    <div className="w-full" dir="rtl">
      <Header
        title="المدفوعات"
        text="اضافه قسم مدفوعات"
        link="/bill-category/form"
      />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">مسلسل</th>
            <th className="p-4">اسم قسم المدفوعات</th>
            <th className="p-4">اجمالى المدفوع</th>
            <th className="text-center w-24 p-4">الدفعات</th>
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تعديل</th>
            )}
            {admin.canDelete === 1 && (
              <th className="text-center w-24 p-4">حذف</th>
            )}
          </tr>
        </thead>
        <tbody>
          {billCategories.map((billCategory) => {
            return (
              <tr
                key={billCategory.billCategoryId}
                className="odd:bg-secondaryBg"
              >
                <td className="p-4">{billCategory.billCategoryId}</td>
                <td className="p-4">{billCategory.billCategoryName}</td>
                <td className="p-4">{billCategory.amount || 0}</td>
                <td className="text-center p-4">
                  <Link
                    to={`/bill-category/instalments/${billCategory.billCategoryId}`}
                    className="flex flex-col items-center w-full cursor-pointer"
                  >
                    💵
                  </Link>
                </td>
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <Link
                      to={`/bill-category/form/${billCategory.billCategoryId}`}
                      className="flex flex-col items-center w-full cursor-pointer"
                    >
                      ✏
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() =>
                        deleteBillCategory(billCategory.billCategoryId)
                      }
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BillCategories;
