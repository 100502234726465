import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Header from "../Header";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ModalContext } from "../../context";
import settings from "../../config";
import { format } from "../../utils";

const Merchants = () => {
  const [merchants, setMerchants] = useState([]);
  const [admin] = useLocalStorage("admin");
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}merchants/`);

      if (response.data.statusCode === 200) {
        setMerchants(response.data.data);
      }
    })();
  }, []);

  const deleteMerchant = async (merchantId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(`${settings.api}merchants/delete`, {
          merchantId,
        });

        if (response.data.statusCode === 200) {
          setMerchants(
            merchants.filter((merchant) => merchantId !== merchant.merchantId),
          );
        }
      },
    });
  };

  let remainingSum = 0;

  return (
    <div className="w-full" dir="rtl">
      <Header title="تجار القماش" text="اضافه تاجر" link="/merchant/form" />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">مسلسل</th>
            <th className="p-4">اسم التاجر</th>
            <th className="p-4">الاجمالى</th>
            <th className="p-4">تم الدفع</th>
            <th className="p-4">المتبقى</th>
            <th className="text-center w-24">الفواتير</th>
            <th className="text-center w-24">الدفعات</th>
            {admin.canEdit === 1 && <th className="text-center w-24">تعديل</th>}
            {admin.canDelete === 1 && <th className="text-center w-24">حذف</th>}
          </tr>
        </thead>
        <tbody>
          {merchants.map((merchant) => {
            remainingSum += merchant.totalPrice - merchant.totalPaid;
            return (
              <tr key={merchant.merchantId} className="odd:bg-secondaryBg">
                <td className="p-4">{merchant.merchantId}</td>
                <td className="p-4">
                  <Link
                    to={`/merchant/details/${merchant.merchantId}`}
                    className="hover:text-sky-500"
                  >
                    {merchant.merchantName}
                  </Link>
                </td>
                <td className="p-4">{format(merchant.totalPrice)}</td>
                <td className="p-4">{format(merchant.totalPaid)}</td>
                <td className="p-4">
                  {format(merchant.totalPrice - merchant.totalPaid)}
                </td>
                <td className="text-center">
                  <Link to={`/merchant/invoices/${merchant.merchantId}`}>
                    💰
                  </Link>
                </td>
                <td className="text-center">
                  <Link
                    to={`/fabric/instalments/${merchant.merchantId}`}
                    className="flex flex-col items-center w-full cursor-pointer"
                  >
                    💵
                  </Link>
                </td>
                {admin.canEdit === 1 && (
                  <td className="text-center">
                    <Link
                      to={`/merchant/form/${merchant.merchantId}`}
                      className="flex flex-col items-center w-full cursor-pointer"
                    >
                      ✏
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() => deleteMerchant(merchant.merchantId)}
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
          <tr>
            <td colSpan={4} className="text-rose-500 p-4">
              اجمالى المتبقى
            </td>
            <td className="text-rose-500 p-4">{format(remainingSum)}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Merchants;
