import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";
import Header from "../Header";
import Input from "../Input/Input";
import Select from "../Select/Select";

const VariantTransactionForm = () => {
  const navigate = useNavigate();
  const { variantId = "", transactionId = null } = useParams();
  const [variant, setVariant] = useState({});
  const [merchants, setMerchants] = useState([]);
  const [formData, setFormData] = useState({
    variantId,
    merchantId: "",
    price: "",
    weight: "",
    transactionType: 1,
    transactionDate: "",
    seasonId: "",
  });

  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}seasons/`);

      if (response.data.statusCode === 200) {
        setSeasons(response.data.data);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}variants/view`, {
        variantId,
      });

      if (response.data.statusCode === 200) {
        setVariant(response.data.data[0]);
      }
    })();

    (async () => {
      const response = await axios.post(`${settings.api}merchants/`);

      if (response.data.statusCode === 200) {
        setMerchants(response.data.data);
      }
    })();
  }, [variantId]);

  useEffect(() => {
    if (transactionId) {
      (async () => {
        const response = await axios.post(
          `${settings.api}variant/view-transaction`,
          {
            transactionId,
          },
        );

        setFormData(response.data.data[0]);
      })();
    }
  }, [transactionId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      dir="rtl"
      onSubmit={async (e) => {
        e.preventDefault();

        const response =
          (transactionId &&
            (await axios.post(
              `${settings.api}variant/update-transaction`,
              formData,
            ))) ||
          (await axios.post(
            `${settings.api}variant/create-transaction`,
            formData,
          ));

        if (response.data.statusCode === 200) {
          navigate(`/variant/transactions/${variantId}`);
        }
      }}
    >
      <div className="w-full">
        <Header title="استماره معامله القماش" />

        <div className="flex flex-col w-full lg:w-1/2 m-auto gap-4">
          <Input
            label="اسم خامه القماش"
            defaultValue={variant.variantName}
            disabled
          />

          <Select
            label="اسم التاجر"
            value={formData.merchantId}
            onChange={onChange}
            name="merchantId"
            required
          >
            <option value="">اختر</option>
            {merchants.map((merchant) => (
              <option key={merchant.merchantId} value={merchant.merchantId}>
                {merchant.merchantName}
              </option>
            ))}
          </Select>

          <Input
            label="سعر الكيلو"
            value={formData.price}
            name="price"
            onChange={onChange}
            required
          />

          <Input
            label="الوزن"
            value={formData.weight}
            name="weight"
            onChange={onChange}
            required
          />

          <Input
            label="السعر الاجمالى"
            value={formData.price * formData.weight || 0}
            disabled
          />

          <Input
            label="تاريخ المعامله"
            placeholder="yyyy-mm-dd"
            value={formData.transactionDate}
            name="transactionDate"
            onChange={onChange}
            required
            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
            type="date"
          />

          <Select
            label="اسم الموسم"
            value={formData.seasonId}
            onChange={onChange}
            name="seasonId"
          >
            <option value="">اختر</option>
            {seasons.map((season) => (
              <option key={season.seasonId} value={season.seasonId}>
                {season.seasonName} - {season.seasonYear}
              </option>
            ))}
          </Select>

          <Button
            type="submit"
            text="تسجيل"
            isDisabled={
              !(
                formData.merchantId &&
                formData.variantId &&
                formData.weight &&
                formData.transactionType &&
                formData.transactionDate
              )
            }
          />
        </div>
      </div>
    </form>
  );
};

export default VariantTransactionForm;
