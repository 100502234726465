import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import Header from "../Header";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ModalContext } from "../../context";

import settings from "../../config";
import { format } from "../../utils";

const FabricVariantTransactions = () => {
  const [productionAssetVariants, setFabricInstalments] = useState([]);
  const { productionAssetId } = useParams();
  const [admin] = useLocalStorage("admin");
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${settings.api}production-assets/variant-transactions`,
        {
          productionAssetId,
        },
      );

      if (response.data.statusCode === 200) {
        setFabricInstalments(response.data.data);
      }
    })();
  }, [productionAssetId]);

  const deleteProductInstalment = async (productionAssetVariantId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}production-assets/delete-variant-transaction`,
          {
            productionAssetVariantId,
          },
        );

        if (response.data.statusCode === 200) {
          setFabricInstalments(
            productionAssetVariants.filter(
              (productionAssetVariant) =>
                productionAssetVariantId !==
                productionAssetVariant.productionAssetTransactionVariantId,
            ),
          );
        }
      },
    });
  };

  return (
    <div className="w-full" dir="rtl">
      <Header
        title="تركيب متطلبات الانتاج"
        text="اضافه منتجات"
        link={`/production-asset/variant/form/${productionAssetId}`}
      />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">كود المنتج</th>
            <th className="p-4">اسم المنتج</th>
            <th className="p-4">التكلفه</th>
            <th className="p-4">تاريخ الدفعه</th>
            <th className="p-4">الموسم</th>
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تعديل</th>
            )}
            {admin.canDelete === 1 && (
              <th className="text-center w-24 p-4">حذف</th>
            )}
          </tr>
        </thead>
        <tbody>
          {productionAssetVariants.map((productionAssetVariant) => {
            return (
              <tr
                key={productionAssetVariant.productionAssetTransactionVariantId}
                className="odd:bg-secondaryBg"
              >
                <td className="p-4">{productionAssetVariant.variantCode}</td>
                <td className="p-4">{productionAssetVariant.variantName}</td>
                <td className="text-center p-4">
                  {format(productionAssetVariant.price || 0)}
                </td>
                <td className="p-4">{productionAssetVariant.processingDate}</td>
                <td className="p-4">{productionAssetVariant.seasonName}</td>
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <Link
                      to={`/production-asset/variant/form/${productionAssetId}/${productionAssetVariant.productionAssetTransactionVariantId}`}
                      className="flex flex-col items-center w-full cursor-pointer"
                    >
                      ✏
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() =>
                        deleteProductInstalment(
                          productionAssetVariant.productionAssetTransactionVariantId,
                        )
                      }
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FabricVariantTransactions;
