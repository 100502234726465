import axios from "axios";
import React, { useEffect, useState } from "react";

import { format } from "../../utils";
import settings from "../../config";
import Header from "../Header";

const DashboardContent = () => {
  const [profitDetails, setProfitDetails] = useState([]);
  const [clientsRemainingBalance, setClientsRemainingBalance] = useState([]);
  const [merchantsRemainingBalance, setMerchantsRemainingBalance] = useState(
    [],
  );
  const [
    accessoriesMerchantsRemainingBalance,
    setAccessoriesMerchantsRemainingBalance,
  ] = useState([]);
  const [fabricDetails, setFabricDetails] = useState([]);
  const [accessoriesDetails, setAccessoriesDetails] = useState([]);
  const [variantsDetails, setVariantsDetails] = useState([]);
  const [currentOpenCards, setCurrentOpenCards] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}dashboard/profit`);

      if (response.data.statusCode === 200) {
        setProfitDetails(response.data.data);
      }

      const clientsRemainingBalanceResponse = await axios.post(
        `${settings.api}dashboard/clients`,
      );

      if (response.data.statusCode === 200) {
        setClientsRemainingBalance(clientsRemainingBalanceResponse.data.data);
      }

      const merchantsRemainingBalanceResponse = await axios.post(
        `${settings.api}dashboard/merchants`,
      );

      if (response.data.statusCode === 200) {
        setMerchantsRemainingBalance(
          merchantsRemainingBalanceResponse.data.data,
        );
      }

      const accessoriesMerchantsRemainingBalanceResponse = await axios.post(
        `${settings.api}dashboard/accessories-merchants`,
      );

      if (response.data.statusCode === 200) {
        setAccessoriesMerchantsRemainingBalance(
          accessoriesMerchantsRemainingBalanceResponse.data.data,
        );
      }

      const fabricDetailsResponse = await axios.post(
        `${settings.api}dashboard/fabric-details`,
      );

      if (response.data.statusCode === 200) {
        setFabricDetails(fabricDetailsResponse.data.data);
      }

      const accessoriesDetailsResponse = await axios.post(
        `${settings.api}dashboard/accessories-details`,
      );

      if (response.data.statusCode === 200) {
        setAccessoriesDetails(accessoriesDetailsResponse.data.data);
      }

      const variantsDetailsResponse = await axios.post(
        `${settings.api}dashboard/variants-details`,
      );

      if (response.data.statusCode === 200) {
        setVariantsDetails(variantsDetailsResponse.data.data);
      }
    })();
  }, []);

  return (
    <div dir="ltr">
      <Header title="الرئيسيه" />
      <div className="flex gap-x-8 gap-y-8 flex-wrap justify-center flex-row-reverse">
        <div
          className="cursor-pointer w-full lg:w-60  hover:bg-textPrimary/10 p-10 bg-gradient-to-b from-secondaryBg rounded-lg"
          onClick={() => {
            if (currentOpenCards.indexOf(1) === -1) {
              setCurrentOpenCards([...currentOpenCards, 1])
            } else {
              setCurrentOpenCards([...currentOpenCards.filter( card => card !== 1)])
            }
          }
          }
        >
          <div className="text-center">الربح</div>
          <div
            className={`text-xl text-center ${currentOpenCards.indexOf(1) === -1 ? "blur-md" : ""}`}
          >
            {format(profitDetails[0]?.profit)}
          </div>
        </div>
        <div
          className="cursor-pointer w-full lg:w-60  hover:bg-textPrimary/10 p-10 bg-gradient-to-b from-secondaryBg rounded-lg"
          onClick={() =>
            {
              if (currentOpenCards.indexOf(2) === -1) {
              setCurrentOpenCards([...currentOpenCards, 2])
            } else {
              setCurrentOpenCards([...currentOpenCards.filter( card => card !== 2)])
            }
            }
          }
        >
          <div className="text-center">المتبقى علي العملاء</div>
          <div
            className={`text-xl text-center ${currentOpenCards.indexOf(2) === -1  ? "blur-md" : ""}`}
          >
            {format(clientsRemainingBalance[0]?.balance)}
          </div>
        </div>
        <div
          className="cursor-pointer w-full lg:w-60  hover:bg-textPrimary/10 p-10 bg-gradient-to-b from-secondaryBg rounded-lg"
          onClick={() =>
            {
              if (currentOpenCards.indexOf(3) === -1) {
              setCurrentOpenCards([...currentOpenCards, 3])
            } else {
              setCurrentOpenCards([...currentOpenCards.filter( card => card !== 3)])
            }
            }
          }
        >
          <div className="text-center">المتبقى من تجار القماش</div>
          <div
            className={`text-xl text-center ${currentOpenCards.indexOf(3) === -1  ? "blur-md" : ""}`}
          >
            {format(merchantsRemainingBalance[0]?.balance)}
          </div>
        </div>
        <div
          className="cursor-pointer w-full lg:w-60  hover:bg-textPrimary/10 p-10 bg-gradient-to-b from-secondaryBg rounded-lg"
          onClick={() =>
            {
              if (currentOpenCards.indexOf(4) === -1) {
              setCurrentOpenCards([...currentOpenCards, 4])
            } else {
              setCurrentOpenCards([...currentOpenCards.filter( card => card !== 4)])
            }
            }
          }
        >
          <div className="text-center">المتبقى من تجار الاكسسوارات</div>
          <div
            className={`text-xl text-center ${currentOpenCards.indexOf(4) === -1  ? "blur-md" : ""}`}
          >
            {format(accessoriesMerchantsRemainingBalance[0]?.balance)}
          </div>
        </div>

        <div
          className="cursor-pointer w-full lg:w-60  hover:bg-textPrimary/10 p-10 bg-gradient-to-b from-secondaryBg rounded-lg"
          onClick={() =>
            {
              if (currentOpenCards.indexOf(5) === -1) {
              setCurrentOpenCards([...currentOpenCards, 5])
            } else {
              setCurrentOpenCards([...currentOpenCards.filter( card => card !== 5)])
            }
            }
          }
        >
          <div className="text-center">سعر القماش المتبقى</div>
          <div
            className={`text-xl text-center ${currentOpenCards.indexOf(5) === -1  ? "blur-md" : ""}`}
          >
            {format(fabricDetails[0]?.totalPrice)}
          </div>
        </div>

        <div
          className="cursor-pointer w-full lg:w-60  hover:bg-textPrimary/10 p-10 bg-gradient-to-b from-secondaryBg rounded-lg"
          onClick={() =>
            {
              if (currentOpenCards.indexOf(6) === -1) {
              setCurrentOpenCards([...currentOpenCards, 6])
            } else {
              setCurrentOpenCards([...currentOpenCards.filter( card => card !== 6)])
            }
            }
          }
        >
          <div className="text-center">سعر الاكسسوارات المتبقى</div>
          <div
            className={`text-xl text-center ${currentOpenCards.indexOf(6) === -1  ? "blur-md" : ""}`}
          >
            {format(accessoriesDetails[0]?.totalPrice)}
          </div>
        </div>

        <div
          className="cursor-pointer w-full lg:w-60  hover:bg-textPrimary/10 p-10 bg-gradient-to-b from-secondaryBg rounded-lg"
          onClick={() =>
            {
              if (currentOpenCards.indexOf(7) === -1) {
              setCurrentOpenCards([...currentOpenCards, 7])
            } else {
              setCurrentOpenCards([...currentOpenCards.filter( card => card !== 7)])
            }
            }
          }
        >
          <div className="text-center">سعر الاصناف المتبقى</div>
          <div
            className={`text-xl text-center ${currentOpenCards.indexOf(7) === -1  ? "blur-md" : ""}`}
          >
            {format(variantsDetails[0]?.totalPrice)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardContent;
