import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";
import Header from "../Header";
import Input from "../Input/Input";

const FabricForm = () => {
  const navigate = useNavigate();
  const { fabricId } = useParams();

  const [formData, setFormData] = useState({
    fabricName: "",
    finalPrice: "",
    seasonId: "",
  });

  useEffect(() => {
    if (fabricId) {
      (async () => {
        const response = await axios.post(`${settings.api}fabrics/view`, {
          fabricId: fabricId,
        });

        if (response.data.data) {
          setFormData(response.data.data[0]);
        }
      })();
    }
  }, [fabricId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      dir="rtl"
      onSubmit={async (e) => {
        e.preventDefault();

        const response = await axios.post(
          `${settings.api}fabrics/${fabricId ? "update" : "create"}`,
          formData,
        );

        if (response.data.statusCode === 200) {
          navigate("/fabrics");
        }
      }}
    >
      <div className="w-full">
        <Header title="استماره القماش" />

        <div className="flex flex-col w-full lg:w-1/2 m-auto gap-4">
          <Input
            label="اسم خامه القماش"
            value={formData.fabricName}
            onChange={onChange}
            name="fabricName"
            required
          />

          <Input
            label="السعر"
            value={formData.finalPrice}
            onChange={onChange}
            name="finalPrice"
            required
          />

          <Button
            type="submit"
            text="تسجيل"
            isDisabled={!formData.fabricName}
          />
        </div>
      </div>
    </form>
  );
};

export default FabricForm;
