import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";
import Header from "../Header";
import Input from "../Input/Input";
import Select from "../Select/Select";

const FabricTransactionForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { fabricId = "", transactionId = null } = useParams();
  const [fabric, setFabric] = useState({});
  const [merchants, setMerchants] = useState([]);

  const [formData, setFormData] = useState({
    fabricId,
    merchantId: "",
    price: "",
    weight: "",
    transactionType: 1,
    transactionDate: "",
    seasonId: "",
  });

  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}seasons/`);

      if (response.data.statusCode === 200) {
        setSeasons(response.data.data);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}fabrics/view`, {
        fabricId,
      });

      if (response.data.statusCode === 200) {
        setFabric(response.data.data[0]);
      }
    })();

    (async () => {
      const response = await axios.post(`${settings.api}merchants/`);

      if (response.data.statusCode === 200) {
        setMerchants(response.data.data);
      }
    })();
  }, [fabricId]);

  useEffect(() => {
    if (transactionId) {
      (async () => {
        const response = await axios.post(
          `${settings.api}fabric/view-transaction`,
          {
            transactionId,
          },
        );

        setFormData(response.data.data[0]);
      })();
    }
  }, [transactionId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      dir="rtl"
      onSubmit={async (e) => {
        e.preventDefault();

        const response =
          (transactionId &&
            (await axios.post(
              `${settings.api}fabric/update-transaction`,
              formData,
            ))) ||
          (await axios.post(
            `${settings.api}fabric/create-transaction`,
            formData,
          ));

        if (response.data.statusCode === 200) {
          if (searchParams.get("from")) {
            navigate(`/${searchParams.get("from")}`);
          } else {
            navigate(`/fabric/transactions/${fabricId}`);
          }
        }
      }}
    >
      <div className="w-full">
        <Header title="استماره معامله القماش" />

        <div className="flex flex-col w-full lg:w-1/2 m-auto gap-4">
          <Input
            label="اسم خامه القماش"
            value={fabric.fabricName}
            onChange={onChange}
            name="fabricName"
            disabled
          />

          <Select
            label="اسم التاجر"
            value={formData.merchantId}
            onChange={onChange}
            name="merchantId"
            required
          >
            <option value="">اختر</option>
            {merchants.map((merchant) => (
              <option key={merchant.merchantId} value={merchant.merchantId}>
                {merchant.merchantName}
              </option>
            ))}
          </Select>

          <Input
            label="سعر الكيلو"
            value={formData.price}
            onChange={onChange}
            name="price"
            required
          />

          <Input
            label="الوزن"
            value={formData.weight}
            onChange={onChange}
            name="weight"
            required
          />

          <Input
            label="السعر الاجمالى"
            value={formData.price * formData.weight || 0}
            onChange={onChange}
            disabled
          />

          <Input
            label="تاريخ المعامله"
            value={formData.transactionDate}
            onChange={onChange}
            name="transactionDate"
            required
            placeholder="yyyy-mm-dd"
            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
            type="date"
          />

          <Select
            label="اسم الموسم"
            value={formData.seasonId}
            onChange={onChange}
            name="seasonId"
          >
            <option value="">اختر</option>
            {seasons.map((season) => (
              <option key={season.seasonId} value={season.seasonId}>
                {season.seasonName} - {season.seasonYear}
              </option>
            ))}
          </Select>

          <Button
            type="submit"
            text="تسجيل"
            isDisabled={
              !(
                formData.merchantId &&
                formData.fabricId &&
                formData.weight &&
                formData.transactionType &&
                formData.transactionDate
              )
            }
          />
        </div>
      </div>
    </form>
  );
};

export default FabricTransactionForm;
