import { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";
import logo from "../../imgs/albasha-logo.png";
import Header from "../Header";
import Input from "../Input/Input";

const MerchantInvoices = () => {
  const { merchantId } = useParams();
  const [data, setData] = useState();

  const [formData, setFormData] = useState({
    transactionDate: "",
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  let totalAmount = 0;
  return (
    <form
      dir="rtl"
      onSubmit={async (e) => {
        e.preventDefault();

        (async () => {
          const response = await axios.post(
            `${settings.api}merchants/invoices`,
            {
              merchantId,
              transactionDate: formData.transactionDate,
            },
          );

          if (response.data.data) {
            setData(response.data.data);
          }
        })();
      }}
    >
      <div className="w-full">
        <Header title="فاتوره للتاجر" />

        <div className="flex flex-row w-1/2 m-auto gap-4 items-end">
          <Input
            label="تاريخ الفاتوره"
            value={formData.transactionDate}
            onChange={onChange}
            name="transactionDate"
            required
          />

          <Button
            type="submit"
            text="بحث"
            isDisabled={!formData.transactionDate}
            className="mx-auto text-center w-1/2 my-2"
          />
        </div>

        <div className="flex flex-col text-sm text-primaryText py-4">
          <div className="flex flex-row justify-center space-x-10 w-full mt-5">
            {data && (
              <div className="flex w-full flex-col">
                <div className="flex w-full items-center">
                  <div className="w-1/3 text-center">
                    مصنع الباشا <br /> للملابس الجاهزه
                  </div>
                  <div className="w-1/3 text-center">قاتورة</div>
                  <div className="w-1/3 text-center flex flex-col justify-center items-center">
                    <img src={logo} alt="logo" className="w-24" />
                  </div>
                </div>
                <div className="flex w-full mt-5">
                  <span className=" text-rose-600 ml-5">تحريرا فى </span>{" "}
                  {formData.transactionDate}
                </div>
                <div className="flex w-full mb-5">
                  <span className=" text-rose-600 ml-5">المطلوب من السيد</span>{" "}
                  {data[0].merchantName}
                </div>

                <div className="flex w-full">
                  <table
                    className="table w-full"
                    cellSpacing={10}
                    cellPadding={10}
                  >
                    <thead>
                      <tr className="border-b">
                        <th className="p-4">السعر</th>
                        <th className="p-4">الوزن</th>
                        <th className="p-4">تاريخ المعامله</th>
                        <th className="p-4">اسم خامه القماش</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((row) => {
                        totalAmount += row.price;
                        return (
                          <tr
                            key={row.fabricTransactionId}
                            className="text-center border-b"
                          >
                            <td className="p-4">{row.price}</td>
                            <td className="p-4">{row.weight}</td>
                            <td className="p-4">{row.transactionDate}</td>
                            <td className="p-4">{row.fabricName}</td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td className="text-center p-4">{totalAmount}</td>
                        <td className="p-4" colSpan={3}>
                          الاجمالى
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default MerchantInvoices;
