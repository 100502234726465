import { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";

import settings from "../../config";
import { format } from "../../utils";

const ClientDetails = () => {
  const [clientDetails, setClientDetails] = useState([]);
  const { clientId = "" } = useParams();
  const [admin] = useLocalStorage("admin");

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}clients/details`, {
        clientId,
      });
      if (response.data.statusCode === 200) {
        setClientDetails(response.data.data);
      }
    })();
  }, [clientId]);

  let totalWithDiscount = 0;
  let finalPrice = 0;
  let totalPrice = 0;

  const boxes = clientDetails.reduce((acc, clientDetail) => {
    return {
      ...acc,
      [clientDetail.category]:
        (acc[clientDetail.category] || 0) + clientDetail.quantity,
    };
  }, {});

  return (
    <div className="w-full" dir="rtl">
      <div className="flex gap-x-8 gap-y-8 flex-wrap mb-4">
        {Object.keys(boxes).map((key) => {
          return (
            <div
              className={`${
                key === "null" ? "bg-gradient-to-b from-rose-100 text-secondaryBg" : "bg-gradient-to-b from-secondaryBg"
              } cursor-pointer w-full lg:w-60  hover:bg-textPrimary/10 p-10  rounded-lg`}
              key={key}
            >
              <div className="text-center">{key}</div>
              <div className="text-xl text-center">{boxes[key]}</div>
            </div>
          );
        })}
      </div>
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">كود الصنف</th>
            <th className="p-4">اسم الصنف</th>
            <th className="p-4">الكميه</th>
            <th className="p-4">سعر القطعه</th>
            <th className="p-4">الاجمالى</th>
            <th className="p-4">الخصم</th>
            <th className="p-4">الاجمالى بالخصم</th>
            <th className="p-4">تاريخ البيع</th>
            {admin.canEdit === 1 && <th className="text-center w-24 p-4">تعديل</th>}
          </tr>
        </thead>
        <tbody>
          {clientDetails.map((clientDetail) => {
            finalPrice = clientDetail.clientPrice * clientDetail.quantity;

            totalWithDiscount = finalPrice;

            if (clientDetail.discountType === 2) {
              totalWithDiscount =
                finalPrice - clientDetail.quantity * clientDetail.discount;
            }

            if (clientDetail.discountType === 1) {
              totalWithDiscount =
                finalPrice - (finalPrice * clientDetail.discount) / 100;
            }

            totalPrice += totalWithDiscount;

            return (
              <tr
                key={clientDetail.variantClientId}
                className={`odd:bg-secondaryBg`}
              >
                <td className="w-24 text-center p-4">{clientDetail.variantCode}</td>
                <td className="p-4">
                  <Link
                    to={`/fabric/variant/form/${clientDetail.fabricTransactionId}/${clientDetail.fabricTransactionVariantId}`}
                  >
                    {clientDetail.variantType}
                  </Link>
                </td>
                <td className="p-4">{clientDetail.quantity}</td>
                <td className="p-4">{format(clientDetail.clientPrice || 0)}</td>
                <td className="p-4">{format(finalPrice || 0)}</td>
                <td className="p-4">
                  {`${(clientDetail.discountType === 1 && "%") || ""}${
                    clientDetail.discount
                  }`}
                </td>
                <td className="p-4">{format(totalWithDiscount || 0)}</td>
                <td className="p-4">{clientDetail.processingDate}</td>
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <Link
                      to={`/variant/sell/form/${clientDetail.fabricTransactionVariantId}/${clientDetail.variantClientId}?from=client/details/${clientId}`}
                    >
                      ✏
                    </Link>
                  </td>
                )}
              </tr>
            );
          })}
          <tr>
            <td className="p-4">الاجمالى</td>
            <td colSpan={5}></td>
            <td className="p-4">{format(totalPrice || 0)}</td>
            <td colSpan={2}></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ClientDetails;
