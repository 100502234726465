import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

import useLocalStorage from "../../hooks/useLocalStorage";
import settings from "../../config";
import { ModalContext } from "../../context";
import Header from "../Header";

const Seasons = () => {
  const [seasons, setSeasons] = useState([]);
  const [admin] = useLocalStorage("admin");
  const { seasonId = "" } = useParams();
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}seasons/`);

      if (response.data.statusCode === 200) {
        setSeasons(response.data.data);
      }
    })();
  }, []);

  const deleteSeason = async (seasonId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(`${settings.api}seasons/delete`, {
          seasonId,
        });

        if (response.data.statusCode === 200) {
          setSeasons(seasons.filter((season) => seasonId !== season.seasonId));
        }
      },
    });
  };

  const activateSeason = async (season) => {
    setModal({
      isModalOpen: true,
      message: `Are you sure you want to ${
        season.active ? "deactivate" : "activate"
      } this season?`,
      modalAction: async () => {
        const newSeason = {
          ...season,
          active: +!season.active,
        };
        const response = await axios.post(
          `${settings.api}seasons/update`,
          newSeason,
        );

        if (response.data.statusCode === 200) {
          setSeasons(
            seasons.map((s) =>
              s.seasonId === season.seasonId ? newSeason : s,
            ),
          );
        }
      },
    });
  };

  return (
    <div className="w-full" dir="rtl">
      <Header
        title="المواسم"
        text="اضافه موسم"
        link={`/season/form/${seasonId}`}
      />

      <table className="w-full table-auto text-right">
        <thead>
          <tr>
            <th className="p-4">الاسم</th>
            <th className="p-4">السنه</th>
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تفعيل</th>
            )}
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تعديل</th>
            )}
            {admin.canDelete === 1 && (
              <th className="text-center w-24 p-4">حذف</th>
            )}
          </tr>
        </thead>
        <tbody>
          {seasons.map((season) => {
            return (
              <tr key={season.seasonId} className="odd:bg-secondaryBg">
                <td className="p-4">{season.seasonName}</td>
                <td className="p-4">{season.seasonYear}</td>
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() => activateSeason(season)}
                    >
                      <div
                        className={`flex rounded-2xl w-10 p-1 ${
                          !season.active
                            ? "bg-primaryText/50 justify-end"
                            : "bg-primaryText"
                        }`}
                      >
                        <div className="flex bg-primaryBg rounded-full h-4 w-1/2"></div>
                      </div>
                    </div>
                  </td>
                )}
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <Link to={`/season/form/${season.seasonId}`}>✏</Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() => deleteSeason(season.seasonId)}
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Seasons;
