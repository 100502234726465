import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

import useLocalStorage from "../../hooks/useLocalStorage";
import settings from "../../config";
import { ModalContext } from "../../context";
import Header from "../Header";

const Discounts = () => {
  const [discounts, setDiscounts] = useState([]);
  const [admin] = useLocalStorage("admin");
  const { seasonId = "" } = useParams();
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}discounts/`);

      if (response.data.statusCode === 200) {
        setDiscounts(response.data.data);
      }
    })();
  }, []);

  const deleteDisount = async (discountId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(`${settings.api}discounts/delete`, {
          discountId,
        });

        if (response.data.statusCode === 200) {
          setDiscounts(discounts.filter((discount) => discountId !== discount.discountId));
        }
      },
    });
  };

  return (
    <div className="w-full" dir="rtl">
      <Header
        title="خصم نهايه الموسم"
        text="اضافه خصم"
        link={`/discount/form`}
      />

      <table className="w-full table-auto text-right">
        <thead>
          <tr>
            <th className="p-4">العميل</th>
            <th className="p-4">قيمه الخصم</th>
            <th className="p-4">تاريخ المعامله</th>
            <th className="p-4">الموسم</th>
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تعديل</th>
            )}
            {admin.canDelete === 1 && (
              <th className="text-center w-24 p-4">حذف</th>
            )}
          </tr>
        </thead>
        <tbody>
          {discounts.map((discount) => {
            return (
              <tr key={discount.discountId} className="odd:bg-secondaryBg">
                <td className="p-4">{discount.clientName}</td>
                <td className="p-4">{discount.discountAmount}</td>
                <td className="p-4">{discount.processingDate}</td>
                <td className="p-4">{discount.seasonName}</td>
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <Link to={`/discount/form/${discount.discountId}`}>✏</Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() => deleteDisount(discount.discountId)}
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Discounts;
