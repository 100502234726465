import { Route, Routes } from "react-router-dom";

import Seasons from "./Seasons";

import Transactions from "./Transactions";

import Employees from "./Employees";
import EmployeeForm from "./Employees/EmployeeForm";

import Merchants from "./Merchants";
import MerchantInvoices from "./Merchants/MerchantInvoices";
import MerchantForm from "./Merchants/MerchantForm";
import MerchantDetails from "./Merchants/MerchantDetails";

import BillCategories from "./BillCategories";
import BillCategoryForm from "./BillCategories/BillCategoryForm";
import BillCategoryInstalments from "./BillCategories/BillCategoryInstalments";
import BillCategoryInstalmentForm from "./BillCategories/BillCategoryInstalmentForm";

import Variants from "./Variants";
import VariantSearch from "./Variants/VariantSearch";
import VariantForm from "./Variants/VariantForm";
import VariantTransactions from "./Variants/VariantTransactions";
import VariantTransactionsWithCode from "./Variants/VariantTransactionsWithCode";
import VariantTransactionForm from "./Variants/VariantTransactionForm";
import VariantDetails from "./Variants/VariantDetails";

import Fabrics from "./Fabrics";
import FabricForm from "./Fabrics/FabricForm";
import FabricTransactions from "./Fabrics/FabricTransactions";
import FabricTransactionForm from "./Fabrics/FabricTransactionForm";
import FabricInstalments from "./Fabrics/FabricInstalments";
import FabricInstalmentForm from "./Fabrics/FabricInstalmentForm";
import FabricCuts from "./Fabrics/FabricCuts";
import FabricCutForm from "./Fabrics/FabricCutForm";
import FabricVariantTransactions from "./Fabrics/FabricVariantTransactions";
import FabricVariantTransactionForm from "./Fabrics/FabricVariantTransactionForm";

import AccessoriesMerchants from "./AccessoriesMerchants";
import AccessoriesMerchantForm from "./AccessoriesMerchants/AccessoriesMerchantForm";
import AccessoriesMerchantDetails from "./AccessoriesMerchants/AccessoriesMerchantDetails";

import Accessories from "./Accessories";
import AccessoryForm from "./Accessories/AccessoryForm";
import AccessoryTransactions from "./Accessories/AccessoryTransactions";
import AccessoryTransactionForm from "./Accessories/AccessoryTransactionForm";
import AccessoryInstalments from "./Accessories/AccessoryInstalments";
import AccessoryInstalmentForm from "./Accessories/AccessoryInstalmentForm";
import AccessoryVariantTransactions from "./Accessories/AccessoryVariantTransactions";
import AccessoryVariantTransactionForm from "./Accessories/AccessoryVariantTransactionForm";

import Clients from "./Clients";
import ClientDetails from "./Clients/ClientDetails";
import ClientForm from "./Clients/ClientForm";
import ClientInvoices from "./Clients/ClientInvoices";

import Assets from "./Assets";
import AssetForm from "./Assets/AssetForm";
import AssetDetails from "./Assets/AssetDetails";
import AssetDetailsForm from "./Assets/AssetDetailForm";

import ProductionAssets from "./ProductionAssets";
import ProductionAssetForm from "./ProductionAssets/ProductionAssetForm";
import ProductionAssetVariantTransactions from "./ProductionAssets/ProductionAssetVariantTransactions";
import ProductionAssetVariantTransactionForm from "./ProductionAssets/ProductionAssetVariantTransactionForm";

import Sell from "./Variants/Sell";
import SellForm from "./Variants/SellForm";
import VariantInstalment from "./Variants/VariantInstalment";
import VariantInstalmentForm from "./Variants/VariantInstalmentForm";
import DashboardContent from "./Dashboard/DashboardContent";

import VariantOperationsForm from "./Variants/VariantOperationsForm";
import FabricInventory from "./FabricInventory";
import FabricInventoryDetails from "./FabricInventoryDetails";
import AccessoriesInventory from "./AccessoriesInventory";
import AccessoriesInventoryDetails from "./AccessoriesInventoryDetails";
import VariantsInventory from "./VariantsInventory";
import VariantsInventoryDetails from "./VariantsInventoryDetails";
import SeasonForm from "./Seasons/SeasonForm";
import Discounts from "./Discounts";
import DiscountForm from "./Discounts/DiscountForm";

const Router = () => {
  return (
    <Routes>
      <Route path="/*" exact element={<DashboardContent />} />

      <Route path="/seasons" exact element={<Seasons />} />
      <Route path="/season/form" exact element={<SeasonForm />} />
      <Route path="/season/form/:seasonId" exact element={<SeasonForm />} />

      <Route path="/discounts" exact element={<Discounts />} />
      <Route path="/discount/form" exact element={<DiscountForm />} />
      <Route path="/discount/form/:discountId" exact element={<DiscountForm />} />

      <Route path="/transactions" exact element={<Transactions />} />

      <Route path="/employees" exact element={<Employees />} />
      <Route path="/employee/form" element={<EmployeeForm />} />
      <Route path="/employee/form/:employeeId" element={<EmployeeForm />} />

      <Route path="/merchants" exact element={<Merchants />} />
      <Route
        path="/merchant/invoices/:merchantId"
        exact
        element={<MerchantInvoices />}
      />
      <Route
        path="/merchant/details/:merchantId"
        exact
        element={<MerchantDetails />}
      />
      <Route path="/merchant/form" exact element={<MerchantForm />} />
      <Route
        path="/merchant/form/:merchantId"
        exact
        element={<MerchantForm />}
      />

      <Route path="/assets" exact element={<Assets />} />
      <Route path="/asset/form" exact element={<AssetForm />} />
      <Route path="/asset/form/:assetId" exact element={<AssetForm />} />
      <Route path="/asset/details/:assetId" exact element={<AssetDetails />} />
      <Route
        path="/asset/details/form/:assetId"
        exact
        element={<AssetDetailsForm />}
      />
      <Route
        path="/asset/details/form/:assetId/:assetDetailId"
        exact
        element={<AssetDetailsForm />}
      />

      <Route path="/bill-categories" exact element={<BillCategories />} />
      <Route path="/bill-category/form" exact element={<BillCategoryForm />} />
      <Route
        path="/bill-category/form/:billCategoryId"
        exact
        element={<BillCategoryForm />}
      />
      <Route
        path="/bill-category/instalments/:billCategoryId"
        exact
        element={<BillCategoryInstalments />}
      />
      <Route
        path="/bill-category/instalments/form/:billCategoryId"
        exact
        element={<BillCategoryInstalmentForm />}
      />
      <Route
        path="/bill-category/instalments/form/:billCategoryId/:billId"
        exact
        element={<BillCategoryInstalmentForm />}
      />

      <Route path="/fabrics" exact element={<Fabrics />} />
      <Route path="/fabric/form" exact element={<FabricForm />} />
      <Route path="/fabric/form/:fabricId" exact element={<FabricForm />} />
      <Route
        path="/fabric/transactions/:fabricId"
        exact
        element={<FabricTransactions />}
      />
      <Route
        path="/fabric/transactions/form/:fabricId"
        exact
        element={<FabricTransactionForm />}
      />
      <Route
        path="/fabric/transactions/form/:fabricId/:transactionId"
        exact
        element={<FabricTransactionForm />}
      />
      <Route
        path="/fabric/instalments/:merchantId"
        exact
        element={<FabricInstalments />}
      />
      <Route
        path="/fabric/instalments/form/:merchantId"
        exact
        element={<FabricInstalmentForm />}
      />
      <Route
        path="/fabric/instalments/form/:merchantId/:fabricInstalmentId"
        exact
        element={<FabricInstalmentForm />}
      />
      <Route path="/fabric/cuts/:fabricId" exact element={<FabricCuts />} />
      <Route
        path="/fabric/cut/form/:fabricId"
        exact
        element={<FabricCutForm />}
      />
      <Route
        path="/fabric/cut/form/:fabricId/:transactionId"
        exact
        element={<FabricCutForm />}
      />
      <Route
        path="/fabric/variants/:fabricTransactionId"
        exact
        element={<FabricVariantTransactions />}
      />
      <Route
        path="/fabric/variant/form/:fabricTransactionId"
        exact
        element={<FabricVariantTransactionForm />}
      />
      <Route
        path="/fabric/variant/form/:fabricTransactionId/:fabricVariantTransactionId"
        exact
        element={<FabricVariantTransactionForm />}
      />

      <Route path="/variants" exact element={<Variants />} />
      <Route path="/variant/search" exact element={<VariantSearch />} />
      <Route
        path="/variant/operations/:fabricTransactionVariantId"
        exact
        element={<VariantOperationsForm />}
      />
      <Route path="/variants/:fabricId" exact element={<Variants />} />
      <Route path="/variant/form" exact element={<VariantForm />} />
      <Route path="/variant/form/:variantId" exact element={<VariantForm />} />
      <Route
        path="/variant/transactions/:variantId"
        exact
        element={<VariantTransactions />}
      />
      <Route
        path="/variant/transactions/:variantId/:variantCode"
        exact
        element={<VariantTransactionsWithCode />}
      />
      <Route
        path="/variant/transactions/form/:variantId"
        exact
        element={<VariantTransactionForm />}
      />
      <Route
        path="/variant/transactions/form/:variantId/:transactionId"
        exact
        element={<VariantTransactionForm />}
      />
      <Route
        path="/variant/details/:fabricTransactionVariantId"
        exact
        element={<VariantDetails />}
      />
      <Route
        path="/variant/sell/:fabricTransactionVariantId"
        exact
        element={<Sell />}
      />
      <Route
        path="/variant/sell/form/:fabricTransactionVariantId"
        exact
        element={<SellForm />}
      />
      <Route
        path="/variant/sell/form/:fabricTransactionVariantId/:variantClientId"
        exact
        element={<SellForm />}
      />
      <Route
        path="/variant/instalments/:clientId"
        exact
        element={<VariantInstalment />}
      />
      <Route
        path="/variant/instalments/form/:clientId"
        exact
        element={<VariantInstalmentForm />}
      />
      <Route
        path="/variant/instalments/form/:clientId/:variantClientInstalmentId"
        exact
        element={<VariantInstalmentForm />}
      />

      <Route
        path="/accessories-merchants"
        exact
        element={<AccessoriesMerchants />}
      />
      <Route
        path="/accessories-merchant/form"
        exact
        element={<AccessoriesMerchantForm />}
      />
      <Route
        path="/accessories-merchant/form/:accessoriesMerchantId"
        exact
        element={<AccessoriesMerchantForm />}
      />
      <Route
        path="/accessories-merchant/details/:accessoriesMerchantId"
        exact
        element={<AccessoriesMerchantDetails />}
      />

      <Route path="/accessories" exact element={<Accessories />} />
      <Route path="/accessory/form" exact element={<AccessoryForm />} />
      <Route
        path="/accessory/form/:accessoryId"
        exact
        element={<AccessoryForm />}
      />
      <Route
        path="/accessory/transactions/:accessoryId"
        exact
        element={<AccessoryTransactions />}
      />
      <Route
        path="/accessory/transactions/form/:accessoryId"
        exact
        element={<AccessoryTransactionForm />}
      />
      <Route
        path="/accessory/transactions/form/:accessoryId/:accessoryTransactionId"
        exact
        element={<AccessoryTransactionForm />}
      />
      <Route
        path="/accessory/instalments/:accessoriesMerchantId"
        exact
        element={<AccessoryInstalments />}
      />
      <Route
        path="/accessory/instalments/form/:accessoriesMerchantId"
        exact
        element={<AccessoryInstalmentForm />}
      />
      <Route
        path="/accessory/instalments/form/:accessoriesMerchantId/:accessoryInstalmentId"
        exact
        element={<AccessoryInstalmentForm />}
      />

      <Route
        path="/accessory/variants/:accessoryId"
        exact
        element={<AccessoryVariantTransactions />}
      />
      <Route
        path="/accessory/variant/form/:accessoryId"
        exact
        element={<AccessoryVariantTransactionForm />}
      />
      <Route
        path="/accessory/variant/form/:accessoryId/:accessoryVariantTransactionId"
        exact
        element={<AccessoryVariantTransactionForm />}
      />

      <Route path="/clients" exact element={<Clients />} />
      <Route path="/client/form" exact element={<ClientForm />} />
      <Route path="/client/form/:clientId" exact element={<ClientForm />} />
      <Route
        path="/client/details/:clientId"
        exact
        element={<ClientDetails />}
      />
      <Route
        path="/client/invoices/:clientId"
        exact
        element={<ClientInvoices />}
      />

      <Route path="/production-assets" exact element={<ProductionAssets />} />
      <Route
        path="/production-asset/form"
        exact
        element={<ProductionAssetForm />}
      />
      <Route
        path="/production-asset/form/:productionAssetId"
        exact
        element={<ProductionAssetForm />}
      />
      <Route
        path="/production-asset/variants/:productionAssetId"
        exact
        element={<ProductionAssetVariantTransactions />}
      />
      <Route
        path="/production-asset/variant/form/:productionAssetId"
        exact
        element={<ProductionAssetVariantTransactionForm />}
      />
      <Route
        path="/production-asset/variant/form/:productionAssetId/:productionAssetVariantTransactionId"
        exact
        element={<ProductionAssetVariantTransactionForm />}
      />
      <Route path="/fabric-inventory" exact element={<FabricInventory />} />
      <Route
        path="/fabric-inventory/:inventoryId"
        exact
        element={<FabricInventoryDetails />}
      />
      <Route
        path="/accessories-inventory"
        exact
        element={<AccessoriesInventory />}
      />
      <Route
        path="/accessories-inventory/:inventoryId"
        exact
        element={<AccessoriesInventoryDetails />}
      />
      <Route path="/variants-inventory" exact element={<VariantsInventory />} />
      <Route
        path="/variants-inventory/:inventoryId"
        exact
        element={<VariantsInventoryDetails />}
      />
    </Routes>
  );
};

export default Router;
