import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Button from "../Button";

import settings from "../../config";
import Header from "../Header";
import Input from "../Input/Input";

const VariantSearch = () => {
  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({
    variantCode: "",
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleStatusChange = async (fabricTransactionVariantId, status) => {
    let finalStatus = 0;
    switch (status) {
      case 0:
        finalStatus = 1;
        break;
      case 1:
        finalStatus = 2;
        break;
      case 2:
        finalStatus = 0;
        break;
      default:
        finalStatus = 0;
        break;
    }
    const response = await axios.post(
      `${settings.api}fabric/status-variant-transaction`,
      {
        fabricTransactionVariantId,
        status: finalStatus,
      },
    );
    if (response.data.data === 1) {
      const newData = data.map((fabricTransactionVariant) => {
        if (
          fabricTransactionVariant.fabricTransactionVariantId ===
          fabricTransactionVariantId
        ) {
          return {
            ...fabricTransactionVariant,
            status: finalStatus,
          };
        } else {
          return fabricTransactionVariant;
        }
      });
      setData([...newData]);
    }
  };

  return (
    <form
      dir="rtl"
      onSubmit={async (e) => {
        e.preventDefault();
        (async () => {
          const response = await axios.post(`${settings.api}variant/code`, {
            variantCode: formData.variantCode,
          });

          if (response.data.statusCode === 200) {
            setData(response.data.data);
          }
        })();
      }}
    >
      <div className="w-full" dir="rtl">
        <Header title="بحث بكود الصنف" />
        <div className=" w-full rounded-xl divide-solid divide-y flex flex-col px-4">
          <div className="flex flex-col text-sm ">
            <div className="flex space-x-10 w-1/2 justify-center items-center">
              <Input
                label="كود الصنف"
                value={formData.variantCode}
                onChange={onChange}
                required
                name="variantCode"
              />

              <div className="w-1/2 text-center">
                <div>
                  <Button
                    type="submit"
                    text="بحث"
                    isDisabled={!formData.variantCode}
                    className="mx-auto text-center w-1/2"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-center space-x-10 w-full mt-5">
              {data && (
                <div className="w-full" dir="rtl">
                  <table className="w-full table-auto text-right ">
                    <thead>
                      <tr>
                        <th className="p-4">الحاله</th>
                        <th className="p-4">كود الصنف</th>
                        <th className="p-4">اسم الصنف</th>
                        <th className="p-4">نوع الصنف</th>
                        <th className="p-4">عدد القطع</th>
                        <th className="p-4">مبيعات</th>
                        <th className="p-4">متاح</th>
                        <th className="p-4">تاريخ المعامله</th>
                        <th className="text-center w-24 p-4">تركيب</th>
                        <th className="text-center w-24 p-4">تفاصيل</th>
                        <th className="text-center w-24 p-4">بيع</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((variantTransaction) => {
                        return (
                          <tr
                            key={variantTransaction.fabricTransactionVariantId}
                            className="odd:bg-secondaryBg"
                          >
                            <td className="text-center p-4">
                              <div className="w-full flex flex-row items-center justify-center">
                                {variantTransaction.status === 0 && (
                                  <div
                                    className="items-center bg-gray-500 w-5 h-5 rounded-full cursor-pointer"
                                    alt="قماش"
                                    title="قماش"
                                    onClick={() =>
                                      handleStatusChange(
                                        variantTransaction.fabricTransactionVariantId,
                                        0,
                                      )
                                    }
                                  />
                                )}
                                {variantTransaction.status === 1 && (
                                  <div
                                    className="items-center bg-rose-500 w-5 h-5 rounded-full cursor-pointer"
                                    alt="دوره تشغيل"
                                    title="دوره تشغيل"
                                    onClick={() =>
                                      handleStatusChange(
                                        variantTransaction.fabricTransactionVariantId,
                                        1,
                                      )
                                    }
                                  />
                                )}
                                {variantTransaction.status === 2 && (
                                  <div
                                    className="items-center bg-green-500 w-5 h-5 rounded-full cursor-pointer"
                                    alt="فى المخزن"
                                    title="فى المخزن"
                                    onClick={() =>
                                      handleStatusChange(
                                        variantTransaction.fabricTransactionVariantId,
                                        2,
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </td>
                            <td className="p-4">
                              {variantTransaction.variantCode}
                            </td>
                            <td className="p-4">
                              {variantTransaction.variantName}
                            </td>
                            <td className="p-4">
                              {variantTransaction.variantType}
                            </td>
                            <td className="p-4">
                              {variantTransaction.quantity}
                            </td>
                            <td className="p-4">{variantTransaction.sold}</td>
                            <td className="p-4">
                              {variantTransaction.quantity -
                                variantTransaction.sold}
                            </td>
                            <td className="p-4">
                              {variantTransaction.processingDate}
                            </td>
                            <td className="text-center p-4">
                              <div className="flex flex-col items-center w-full cursor-pointer">
                                <div className="font-bold rounded-full w-8">
                                  <Link
                                    to={`/variant/operations/${variantTransaction.fabricTransactionVariantId}`}
                                  >
                                    👕
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td className="text-center p-4">
                              <div className="flex flex-col items-center w-full cursor-pointer">
                                <div className="font-bold rounded-full w-8">
                                  <Link
                                    to={`/variant/details/${variantTransaction.fabricTransactionVariantId}`}
                                  >
                                    📄
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td className="text-center p-4">
                              <div className="flex flex-col items-center w-full cursor-pointer">
                                <div className="font-bold rounded-full w-8">
                                  <Link
                                    to={`/variant/sell/${variantTransaction.fabricTransactionVariantId}`}
                                  >
                                    🚚
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default VariantSearch;
