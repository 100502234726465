import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import Header from "../Header";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ModalContext } from "../../context";

import settings from "../../config";
import { format } from "../../utils";

const FabricVariantTransactions = () => {
  const [accessoryVariants, setFabricInstalments] = useState([]);
  const { accessoryId } = useParams();
  const [admin] = useLocalStorage("admin");
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${settings.api}accessory/variant-transactions`,
        {
          accessoryId,
        },
      );

      if (response.data.statusCode === 200) {
        setFabricInstalments(response.data.data);
      }
    })();
  }, [accessoryId]);

  const deleteProductInstalment = async (accessoryVariantId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}accessory/delete-variant-transaction`,
          {
            accessoryVariantId,
          },
        );

        if (response.data.statusCode === 200) {
          setFabricInstalments(
            accessoryVariants.filter(
              (accessoryVariant) =>
                accessoryVariantId !==
                accessoryVariant.accessoryTransactionVariantId,
            ),
          );
        }
      },
    });
  };

  return (
    <div className="w-full" dir="rtl">
      <Header
        title="تركيب الاكسسوارات"
        text="اضافه منتجات"
        link={`/accessory/variant/form/${accessoryId}`}
      />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">كود المنتج</th>
            <th className="p-4">اسم المنتج</th>
            <th className="p-4">العدد</th>
            <th className="p-4">السعر بالقطعه</th>
            <th className="p-4">السعر الاجمالى</th>
            <th className="p-4">تاريخ الدفعه</th>
            <th className="p-4">الموسم</th>
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تعديل</th>
            )}
            {admin.canDelete === 1 && (
              <th className="text-center w-24 p-4">حذف</th>
            )}
          </tr>
        </thead>
        <tbody>
          {accessoryVariants.map((accessoryVariant) => {
            return (
              <tr
                key={accessoryVariant.accessoryTransactionVariantId}
                className="odd:bg-secondaryBg"
              >
                <td className="p-4">{accessoryVariant.variantCode}</td>
                <td className="p-4">{accessoryVariant.variantName}</td>
                <td className="p-4">{accessoryVariant.quantity}</td>
                <td className="p-4">{format(accessoryVariant.price || 0)}</td>
                <td className="p-4">
                  {format(
                    accessoryVariant.price * accessoryVariant.quantity || 0,
                  )}
                </td>
                <td className="p-4">{accessoryVariant.processingDate}</td>
                <td className="p-4">{accessoryVariant.seasonName}</td>
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <Link
                      to={`/accessory/variant/form/${accessoryId}/${accessoryVariant.accessoryTransactionVariantId}`}
                      className="flex flex-col items-center w-full cursor-pointer"
                    >
                      ✏
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() =>
                        deleteProductInstalment(
                          accessoryVariant.accessoryTransactionVariantId,
                        )
                      }
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FabricVariantTransactions;
