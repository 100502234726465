import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";
import Header from "../Header";
import Input from "../Input/Input";
import Select from "../Select/Select";

const AssetDetailsForm = () => {
  const navigate = useNavigate();
  const { assetId = "", assetDetailId = null } = useParams();

  const [formData, setFormData] = useState({
    assetId,
    quantity: 0,
    price: 0,
    instalmentDate: "",
    seasonId: "",
  });

  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}seasons/`);

      if (response.data.statusCode === 200) {
        setSeasons(response.data.data);
      }
    })();
  }, []);

  useEffect(() => {
    if (assetDetailId) {
      (async () => {
        const response = await axios.post(
          `${settings.api}assets/view-details`,
          {
            assetDetailId,
          },
        );

        setFormData(response.data.data[0]);
      })();
    }
  }, [assetDetailId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      dir="rtl"
      onSubmit={async (e) => {
        e.preventDefault();

        const response =
          (assetDetailId &&
            (await axios.post(
              `${settings.api}assets/update-details`,
              formData,
            ))) ||
          (await axios.post(`${settings.api}assets/create-details`, formData));

        if (response.data.statusCode === 200) {
          navigate(`/asset/details/${assetId}`);
        }
      }}
    >
      <div className="w-full">
        <Header title="استماره تفاصيل الاصل" />

        <div className="flex flex-col w-full lg:w-1/2 m-auto gap-4">
          <Input
            label="العدد"
            value={formData.quantity}
            onChange={onChange}
            name="quantity"
          />

          <Input
            label="السعر"
            value={formData.price}
            onChange={onChange}
            name="price"
          />

          <Input
            label="تاريخ الدفعه"
            value={formData.instalmentDate}
            onChange={onChange}
            name="instalmentDate"
            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
            placeholder="yyyy-mm-dd"
            type="date"
          />

          <Select
            label="اسم الموسم"
            value={formData.seasonId}
            onChange={onChange}
            name="seasonId"
          >
            <option value="">اختر</option>
            {seasons.map((season) => (
              <option key={season.seasonId} value={season.seasonId}>
                {season.seasonName} - {season.seasonYear}
              </option>
            ))}
          </Select>

          <Button
            className="w-1/2 m-auto"
            type="submit"
            text="تسجيل"
            isDisabled={
              !(
                formData.assetId &&
                formData.price &&
                formData.quantity &&
                formData.instalmentDate
              )
            }
          />
        </div>
      </div>
    </form>
  );
};

export default AssetDetailsForm;
