import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";
import Header from "../Header";
import Input from "../Input/Input";

const ClientForm = () => {
  const navigate = useNavigate();
  const { clientId } = useParams();

  const [formData, setFormData] = useState({
    clientName: "",
    clientCode: "",
    price: 0,
    stock: 0,
    seasonId: "",
  });

  useEffect(() => {
    if (clientId) {
      (async () => {
        const response = await axios.post(`${settings.api}clients/view`, {
          clientId,
        });

        if (response.data.data) {
          setFormData(response.data.data[0]);
        }
      })();
    }
  }, [clientId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      dir="rtl"
      onSubmit={async (e) => {
        e.preventDefault();

        const response = await axios.post(
          `${settings.api}clients/${clientId ? "update" : "create"}`,
          formData,
        );

        if (response.data.statusCode === 200) {
          navigate("/clients");
        }
      }}
    >
      <div className="w-full">
        <Header title="استماره العميل" />

        <div className="flex flex-col w-full lg:w-1/2 m-auto gap-4">
          <Input
            label="كود العميل"
            value={formData.clientCode}
            onChange={onChange}
            required
            name="clientCode"
          />

          <Input
            label="اسم العميل"
            value={formData.clientName}
            onChange={onChange}
            required
            name="clientName"
          />

          <Button
            type="submit"
            text="تسجيل"
            isDisabled={!(formData.clientName && formData.clientCode)}
          />
        </div>
      </div>
    </form>
  );
};

export default ClientForm;
