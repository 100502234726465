import { useCallback, useEffect, useState, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import useLocalStorage from "../../hooks/useLocalStorage";
import { ModalContext } from "../../context";

import Button from "../Button";
import settings from "../../config";
import Header from "../Header";
import Input from "../Input/Input";
import Select from "../Select/Select";

const VariantOperationsDetails = () => {
  const [variantDetails, setVariantDetails] = useState([]);
  const [admin] = useLocalStorage("admin");

  const [, setModal] = useContext(ModalContext);

  const { fabricTransactionVariantId } = useParams();
  const [accessories, setAccessories] = useState([]);
  const [productionAssets, setProductionAssets] = useState([]);

  const initAccessoriesFormData = {
    accessoryTransactionVariantId: "",
    accessoryId: "",
    fabricTransactionVariantId,
    quantity: "",
    price: "",
    processingDate: "",
    seasonId: "",
  };

  const initProductionAssetsFormData = {
    productionAssetTransactionVariantId: "",
    productionAssetId: "",
    fabricTransactionVariantId,
    price: "",
    processingDate: "",
    seasonId: "",
  };

  const [accessoriesFormData, setAccessoriesFormData] = useState(
    initAccessoriesFormData,
  );

  const [productionAssetsFormData, setProductionAssetsFormData] = useState(
    initProductionAssetsFormData,
  );

  const onAccessoriesChange = (e) => {
    setAccessoriesFormData({
      ...accessoriesFormData,
      [e.target.name]: e.target.value,
    });
  };

  const onProductionAssetsChange = (e) => {
    setProductionAssetsFormData({
      ...productionAssetsFormData,
      [e.target.name]: e.target.value,
    });
  };

  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}seasons/`);

      if (response.data.statusCode === 200) {
        setSeasons(response.data.data);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}accessories/`);

      setAccessories(response.data.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}production-assets/`);

      setProductionAssets(response.data.data);
    })();
  }, []);

  const onEditAccessory = (accessory) => {
    setAccessoriesFormData({
      accessoryTransactionVariantId: accessory.accessoryTransactionVariantId,
      accessoryId: accessory.fabricId,
      fabricTransactionVariantId: accessory.fabricTransactionVariantId,
      quantity: accessory.quantity,
      price: accessory.price,
      processingDate: accessory.processingDate,
      seasonId: accessory.seasonId,
    });
  };

  const onEditProductionAsset = (productionAsset) => {
    setProductionAssetsFormData({
      productionAssetTransactionVariantId:
        productionAsset.accessoryTransactionVariantId,
      productionAssetId: productionAsset.fabricId,
      fabricTransactionVariantId: productionAsset.fabricTransactionVariantId,
      price: productionAsset.price,
      processingDate: productionAsset.processingDate,
      seasonId: productionAsset.seasonId,
    });
  };

  const getVariantTransactionDetails = useCallback(async () => {
    const response = await axios.post(
      `${settings.api}fabric/variant-transaction-details`,
      {
        fabricTransactionVariantId,
      },
    );
    setVariantDetails(response.data.data);
  }, [fabricTransactionVariantId]);

  useEffect(() => {
    getVariantTransactionDetails();
  }, [getVariantTransactionDetails]);

  const fabric =
    (variantDetails || []).find(
      (variantDetail) => variantDetail.type === "fabric",
    ) || {};
  const filteredAccessories =
    (variantDetails || []).filter(
      (variantDetail) => variantDetail.type === "accessory",
    ) || [];
  const filteredProductionAssets =
    (variantDetails || []).filter(
      (variantDetail) => variantDetail.type === "production_asset",
    ) || [];

  const accessoriesTotalPrice = filteredAccessories.reduce((acc, accessory) => {
    return accessory.price * accessory.quantity + acc;
  }, 0);

  const productionAssetsTotalPrice = filteredProductionAssets.reduce(
    (acc, productionAsset) => {
      return productionAsset.price + acc;
    },
    0,
  );

  const deleteAccessoryVariant = async (accessoryVariantId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}accessory/delete-variant-transaction`,
          {
            accessoryVariantId,
          },
        );

        if (response.data.statusCode === 200) {
          setVariantDetails(
            variantDetails.filter(
              (accessoryVariant) =>
                accessoryVariantId !==
                accessoryVariant.accessoryTransactionVariantId,
            ),
          );
        }
      },
    });
  };

  const deleteProductInstalment = async (productionAssetVariantId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}production-assets/delete-variant-transaction`,
          {
            productionAssetVariantId,
          },
        );

        if (response.data.statusCode === 200) {
          setVariantDetails(
            variantDetails.filter(
              (productionAssetVariant) =>
                productionAssetVariantId !==
                productionAssetVariant.accessoryTransactionVariantId,
            ),
          );
        }
      },
    });
  };

  return (
    <div className="w-full ">
      <Header title={fabric.variantName} />

      <div className="w-full" dir="rtl">
        <div className="text-right w-full my-5">
          اجمالى عدد القطع: {fabric.quantity}
        </div>
        <div className="text-right w-full my-5 text-green-500">
          سعر القطعه الواحده:{" "}
          {fabric.price + accessoriesTotalPrice + productionAssetsTotalPrice}
        </div>
        <div className="text-right w-full my-5 text-red-500">
          اجمالى الطلبيه:{" "}
          {(fabric.price + accessoriesTotalPrice + productionAssetsTotalPrice) *
            fabric.quantity}
        </div>
        <div className="text-right w-full my-5">القماش</div>
        <table className="w-full table-auto text-right ">
          <thead>
            <tr>
              <th className="p-4">خامه القماش</th>
              <th className="p-4">عدد الوحدات</th>
              <th className="text-rose-500 p-4">سعر القماش للقطعه</th>
            </tr>
          </thead>
          <tbody>
            <tr className="odd:bg-secondaryBg">
              <td className="p-4">{fabric.fabricName}</td>
              <td className="p-4">{fabric.quantity}</td>
              <td className="text-rose-500 p-4">{fabric.price || 0}</td>
            </tr>
          </tbody>
        </table>
        <hr className="my-5 border border-primaryText/20" />
        <div className="text-right w-full">الاكسسوارات</div>
        <table className="w-full table-auto text-right ">
          <thead>
            <tr>
              <th className="p-4">الاكسسوار</th>
              <th className="p-4">عدد الوحدات</th>
              <th className="p-4">السعر للقطعه</th>
              {admin.canEdit === 1 && (
                <th className="text-center w-24 p-4">تعديل</th>
              )}
              {admin.canDelete === 1 && (
                <th className="text-center w-24 p-4">حذف</th>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredAccessories.map((accessory) => (
              <tr
                className="odd:bg-secondaryBg"
                key={`accessory-${accessory.accessoryName}`}
              >
                <td className="p-4">{accessory.accessoryName}</td>
                <td className="p-4">{accessory.quantity}</td>
                <td className="p-4">{accessory.price}</td>
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() => onEditAccessory(accessory)}
                    >
                      ✏
                    </div>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() =>
                        deleteAccessoryVariant(
                          accessory.accessoryTransactionVariantId,
                        )
                      }
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            ))}
            <tr className="text-rose-500">
              <td className="p-4">الاجمالى</td>
              <td className="p-4"></td>
              <td className="p-4">{accessoriesTotalPrice}</td>
            </tr>
          </tbody>
        </table>
        <form
          dir="rtl"
          onSubmit={async (e) => {
            e.preventDefault();

            const response = await axios.post(
              `${settings.api}accessory/${
                accessoriesFormData.accessoryTransactionVariantId !== ""
                  ? "update"
                  : "create"
              }-variant-transaction`,
              accessoriesFormData,
            );

            if (response.data.data) {
              getVariantTransactionDetails();
              setAccessoriesFormData(initAccessoriesFormData);
            } else {
              setAccessoriesFormData(initAccessoriesFormData);
            }
          }}
        >
          <div className="flex flex-col w-full lg:w-1/2 m-auto gap-4">
            <input
              type="hidden"
              name="fabricTransactionVariantId"
              value={fabricTransactionVariantId}
            />
            <Select
              label="الاكسسوار"
              value={accessoriesFormData.accessoryId}
              onChange={onAccessoriesChange}
              name="accessoryId"
            >
              <option value="">اختر</option>
              {accessories.map((accessory) => (
                <option
                  key={accessory.accessoryId}
                  value={accessory.accessoryId}
                >
                  {`${accessory.accessoryName}`}
                </option>
              ))}
            </Select>
            <Input
              label="العدد"
              value={accessoriesFormData.quantity}
              onChange={onAccessoriesChange}
              name="quantity"
              required
            />
            <Input
              label="التكلفه للقطعه"
              value={accessoriesFormData.price}
              onChange={onAccessoriesChange}
              name="price"
              required
            />
            <Input
              label="تاريخ الدفعه"
              value={accessoriesFormData.processingDate}
              onChange={onAccessoriesChange}
              name="processingDate"
              required
              placeholder="yyyy-mm-dd"
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
              type="date"
            />

            <Select
              label="اسم الموسم"
              value={accessoriesFormData.seasonId}
              onChange={onAccessoriesChange}
              name="seasonId"
            >
              <option value="">اختر</option>
              {seasons.map((season) => (
                <option key={season.seasonId} value={season.seasonId}>
                  {season.seasonName} - {season.seasonYear}
                </option>
              ))}
            </Select>

            <Button
              type="submit"
              text="تسجيل"
              isDisabled={
                !(
                  (accessoriesFormData.fabricTransactionVariantId ||
                    accessoriesFormData.accessoryId) &&
                  accessoriesFormData.quantity &&
                  accessoriesFormData.processingDate &&
                  accessoriesFormData.price
                )
              }
            />
          </div>
        </form>
        <hr className="my-5 border border-primaryText/20" />
        <div className="text-right w-full my-5">متطلبات انتاج</div>
        <table className="w-full table-auto text-right ">
          <thead>
            <tr>
              <th className="p-4">الثابت</th>
              <th className="p-4">السعر للقطعه</th>
              {admin.canEdit === 1 && (
                <th className="text-center w-24 p-4">تعديل</th>
              )}
              {admin.canDelete === 1 && (
                <th className="text-center w-24 p-4">حذف</th>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredProductionAssets.map((productionAsset) => (
              <tr
                className="odd:bg-secondaryBg"
                key={`productionAsset-${productionAsset.productionAssetName}`}
              >
                <td className="p-4">{productionAsset.productionAssetName}</td>
                <td className="p-4">{productionAsset.price}</td>
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() => onEditProductionAsset(productionAsset)}
                    >
                      ✏
                    </div>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() =>
                        deleteProductInstalment(
                          productionAsset.accessoryTransactionVariantId,
                        )
                      }
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            ))}
            <tr className="text-rose-500">
              <td className="p-4">الاجمالى</td>
              <td className="p-4">{productionAssetsTotalPrice}</td>
            </tr>
          </tbody>
        </table>
        <form
          dir="rtl"
          onSubmit={async (e) => {
            e.preventDefault();

            e.preventDefault();

            const response = await axios.post(
              `${settings.api}production-assets/${
                productionAssetsFormData.productionAssetTransactionVariantId !==
                ""
                  ? "update"
                  : "create"
              }-variant-transaction`,
              productionAssetsFormData,
            );

            if (response.data.data) {
              getVariantTransactionDetails();
              setProductionAssetsFormData(initProductionAssetsFormData);
            } else {
              setProductionAssetsFormData(initProductionAssetsFormData);
            }
          }}
        >
          <div className="flex flex-col w-full lg:w-1/2 m-auto gap-4">
            <input
              type="hidden"
              name="fabricTransactionVariantId"
              value={fabricTransactionVariantId}
            />

            <Select
              label="متطلبات الانتاج"
              value={productionAssetsFormData.productionAssetId}
              onChange={onProductionAssetsChange}
              name="productionAssetId"
            >
              <option value="">اختر</option>
              {productionAssets.map((productionAsset) => (
                <option
                  key={productionAsset.productionAssetId}
                  value={productionAsset.productionAssetId}
                >
                  {`${productionAsset.productionAssetName}`}
                </option>
              ))}
            </Select>

            <Input
              label="التكلفه"
              value={productionAssetsFormData.price}
              onChange={onProductionAssetsChange}
              name="price"
              required
            />

            <Input
              label="تاريخ الدفعه"
              value={productionAssetsFormData.processingDate}
              onChange={onProductionAssetsChange}
              name="processingDate"
              required
              placeholder="yyyy-mm-dd"
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
              type="date"
            />

            <Select
              label="اسم الموسم"
              value={productionAssetsFormData.seasonId}
              onChange={onProductionAssetsChange}
              name="seasonId"
            >
              <option value="">اختر</option>
              {seasons.map((season) => (
                <option key={season.seasonId} value={season.seasonId}>
                  {season.seasonName} - {season.seasonYear}
                </option>
              ))}
            </Select>

            <Button
              type="submit"
              text="تسجيل"
              isDisabled={
                !(
                  (productionAssetsFormData.fabricTransactionVariantId ||
                    productionAssetsFormData.productionAssetId) &&
                  productionAssetsFormData.processingDate &&
                  productionAssetsFormData.price
                )
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default VariantOperationsDetails;
