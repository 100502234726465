import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";
import Input from "../Input/Input";
import Header from "../Header";
import Select from "../Select/Select";

const DiscountForm = () => {
  const navigate = useNavigate();
  const { discountId } = useParams();

  const [formData, setFormData] = useState({
    discountId,
    discountAmount: "",
    clientId: "",
    seasonId: "",
  });
  const [clients, setClients] = useState([]);
  const [seasons, setSeasons] = useState([]);


  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}seasons/`);

      if (response.data.statusCode === 200) {
        setSeasons(response.data.data);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}clients/`);
      setClients(response.data.data);
    })();
  }, []);

  useEffect(() => {
    if (discountId) {
      (async () => {
        const response = await axios.post(`${settings.api}discounts/details`, {
          discountId,
        });

        if (response.data.data) {
          setFormData((oldState) => ({
            ...oldState,
            ...response.data.data[0],
          }));
        }
      })();
    }
  }, [discountId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      dir="rtl"
      onSubmit={async (e) => {
        e.preventDefault();

        const response = await axios.post(
          `${settings.api}discounts/${discountId ? "update" : "create"}`,
          formData,
        );

        if (response.data.statusCode === 200) {
          navigate(`/discounts`);
        }
      }}
    >
      <div className="w-full">
        <Header title="استماره خصم نهايه الموسم" />

        <div className="flex flex-col w-full lg:w-1/2 m-auto gap-4">
          <Select
            label="اسم العميل"
            value={formData.clientId}
            onChange={onChange}
            required
            name="clientId"
          >
            <option value="">اختر</option>
            {clients.map((client) => (
              <option key={client.clientId} value={client.clientId}>
                {client.clientName} - {client.clientCode}
              </option>
            ))}
          </Select>

          <Input
            label="قيمه الخصم"
            name="discountAmount"
            value={formData.discountAmount}
            onChange={onChange}
            required
          />

          <Input
            label="تاريخ المعامله"
            value={formData.processingDate}
            onChange={onChange}
            name="processingDate"
            required
            placeholder="yyyy-mm-dd"
            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
            type="date"
          />

          <Select
            label="اسم الموسم"
            value={formData.seasonId}
            onChange={onChange}
            name="seasonId"
            required
          >
            <option value="">اختر</option>
            {seasons.map((season) => (
              <option key={season.seasonId} value={season.seasonId}>
                {season.seasonName} - {season.seasonYear}
              </option>
            ))}
          </Select>

          <Button
            className="w-1/2 m-auto"
            type="submit"
            text="تسجيل"
            isDisabled={!(formData.seasonId && formData.discountAmount && formData.clientId)}
          />
        </div>
      </div>
    </form>
  );
};

export default DiscountForm;
