import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import settings from "../../config";
import Header from "../Header";

const VariantDetails = () => {
  const { fabricTransactionVariantId } = useParams();

  const [variantDetails, setVariantDetails] = useState([]);

  useEffect(() => {
    if (!variantDetails?.length) {
      (async () => {
        const response = await axios.post(
          `${settings.api}fabric/variant-transaction-details`,
          {
            fabricTransactionVariantId,
          },
        );

        setVariantDetails(response.data.data);
      })();
    }
  }, [fabricTransactionVariantId, variantDetails?.length]);

  const fabric =
    variantDetails.find((variantDetail) => variantDetail.type === "fabric") ||
    {};
  const accessories =
    variantDetails.filter(
      (variantDetail) => variantDetail.type === "accessory",
    ) || [];
  const productionAssets =
    variantDetails.filter(
      (variantDetail) => variantDetail.type === "production_asset",
    ) || [];

  const accessoriesTotalPrice = accessories.reduce((acc, accessory) => {
    return accessory.price * accessory.quantity + acc;
  }, 0);

  const productionAssetsTotalPrice = productionAssets.reduce(
    (acc, productionAsset) => {
      return productionAsset.price + acc;
    },
    0,
  );

  return (
    <div className="w-full ">
      <Header title={fabric.variantName} />

      <div className="w-full" dir="rtl">
        <div className="text-right w-full my-5">
          اجمالى عدد القطع: {fabric.quantity}
        </div>
        <div className="text-right w-full my-5 text-green-500">
          سعر القطعه الواحده:{" "}
          {fabric.price + accessoriesTotalPrice + productionAssetsTotalPrice}
        </div>
        <div className="text-right w-full my-5 text-red-500">
          اجمالى الطلبيه:{" "}
          {(fabric.price + accessoriesTotalPrice + productionAssetsTotalPrice) *
            fabric.quantity}
        </div>
        <div className="text-right w-full my-5">القماش</div>
        <table className="table-auto w-full text-right">
          <thead>
            <tr>
              <th className="p-4">خامه القماش</th>
              <th className="p-4">عدد الوحدات</th>
              <th className="text-rose-500 p-4">سعر القماش للقطعه</th>
            </tr>
          </thead>
          <tbody>
            <tr className="odd:bg-secondaryBg">
              <td className="p-4">{fabric.fabricName}</td>
              <td className="p-4">{fabric.quantity}</td>
              <td className="text-rose-500 p-4">{fabric.price || 0}</td>
            </tr>
          </tbody>
        </table>
        <hr className="my-5 border border-primaryText/20" />
        <div className="text-right w-full">الاكسسوارات</div>
        <table className="table-auto w-full text-right">
          <thead>
            <tr>
              <th className="p-4">الاكسسوار</th>
              <th className="p-4">عدد الوحدات</th>
              <th className="p-4">السعر للقطعه</th>
            </tr>
          </thead>
          <tbody>
            {accessories.map((accessory) => (
              <tr
                className="odd:bg-secondaryBg"
                key={`accessory-${accessory.accessoryName}`}
              >
                <td className="p-4">{accessory.accessoryName}</td>
                <td className="p-4">{accessory.quantity}</td>
                <td className="p-4">{accessory.price}</td>
              </tr>
            ))}
            <tr className="text-rose-500">
              <td className="p-4">الاجمالى</td>
              <td></td>
              <td className="p-4">{accessoriesTotalPrice}</td>
            </tr>
          </tbody>
        </table>
        <hr className="my-5 border border-primaryText/20" />
        <div className="text-right w-full">متطلبات انتاج</div>
        <table className="table-auto w-full text-right">
          <thead>
            <tr>
              <th className="p-4">الثابت</th>
              <th className="p-4">السعر للقطعه</th>
            </tr>
          </thead>
          <tbody>
            {productionAssets.map((productionAsset) => (
              <tr
                className="odd:bg-secondaryBg"
                key={`productionAsset-${productionAsset.productionAssetName}`}
              >
                <td className="p-4">{productionAsset.productionAssetName}</td>
                <td className="p-4">{productionAsset.price}</td>
              </tr>
            ))}
            <tr className="text-rose-500">
              <td className="p-4">الاجمالى</td>
              <td className="p-4">{productionAssetsTotalPrice}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VariantDetails;
