import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import Header from "../Header";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ModalContext } from "../../context";

import settings from "../../config";
import { format } from "../../utils";

const FabricInstalments = () => {
  const [fabricInstalments, setFabricInstalments] = useState([]);
  const { merchantId } = useParams();
  const [admin] = useLocalStorage("admin");
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}fabric/instalments`, {
        merchantId,
      });

      if (response.data.statusCode === 200) {
        setFabricInstalments(response.data.data);
      }
    })();
  }, [merchantId]);

  const deleteProductInstalment = async (fabricInstalmentId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}fabric/delete-instalment`,
          {
            fabricInstalmentId,
          },
        );

        if (response.data.statusCode === 200) {
          setFabricInstalments(
            fabricInstalments.filter(
              (fabricInstalment) =>
                fabricInstalmentId !== fabricInstalment.fabricInstalmentId,
            ),
          );
        }
      },
    });
  };

  let totalPaid = 0;

  return (
    <div className="w-full" dir="rtl">
      <Header
        title="دفعات تاجر القماش"
        text="اضافه دفعه"
        link={`/fabric/instalments/form/${merchantId}`}
      />
      <table className="w-full table-auto text-right ">
        <thead>
          <tr>
            <th className="p-4">اسم التاجر</th>
            <th className="p-4">المبلغ الاجمالى</th>
            <th className="p-4">قيمه الدفعه</th>
            <th className="p-4">خصم</th>
            <th className="p-4">المبلغ المتبقى</th>
            <th className="p-4">تاريخ الدفعه</th>
            {admin.canEdit === 1 && <th className="text-center w-24">تعديل</th>}
            {admin.canDelete === 1 && <th className="text-center w-24">حذف</th>}
          </tr>
        </thead>
        <tbody>
          {fabricInstalments.map((fabricInstalment) => {
            totalPaid += fabricInstalment.totalPaid + fabricInstalment.discount;

            return (
              <tr
                key={fabricInstalment.fabricInstalmentId}
                className=" odd:bg-secondaryBg "
              >
                <td className="p-4">{fabricInstalment.merchantName}</td>
                <td className="p-4">{format(fabricInstalment.amount || 0)}</td>
                <td className="p-4">
                  {format(fabricInstalment.totalPaid || 0)}
                </td>
                <td className="p-4">
                  {format(fabricInstalment.discount || 0)}
                </td>
                <td className="p-4">
                  {format(fabricInstalment.amount - totalPaid || 0)}
                </td>
                <td className="p-4">{fabricInstalment.instalmentDate}</td>
                {admin.canEdit === 1 && (
                  <td className="text-center">
                    <Link
                      to={`/fabric/instalments/form/${merchantId}/${fabricInstalment.fabricInstalmentId}`}
                      className="flex flex-col items-center w-full cursor-pointer"
                    >
                      ✏
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() =>
                        deleteProductInstalment(
                          fabricInstalment.fabricInstalmentId,
                        )
                      }
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FabricInstalments;
