import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import Header from "../Header";
import Input from "../Input/Input";

import settings from "../../config";

const AccessoryForm = () => {
  const navigate = useNavigate();
  const { accessoryId } = useParams();

  const [formData, setFormData] = useState({
    accessoryName: "",
    finalPrice: "",
    seasonId: "",
  });

  useEffect(() => {
    if (accessoryId) {
      (async () => {
        const response = await axios.post(`${settings.api}accessories/view`, {
          accessoryId: accessoryId,
        });

        if (response.data.data) {
          setFormData(response.data.data[0]);
        }
      })();
    }
  }, [accessoryId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        const response = await axios.post(
          `${settings.api}accessories/${accessoryId ? "update" : "create"}`,
          formData,
        );

        if (response.data.statusCode === 200) {
          navigate("/accessories");
        }
      }}
    >
      <div className="w-full">
        <Header title="استماره الاكسسوار" />

        <div className="flex flex-col w-full lg:w-1/2 m-auto gap-4">
          <Input
            label="اسم الاكسسوار"
            value={formData.accessoryName}
            onChange={onChange}
            required
            name="accessoryName"
          />

          <Input
            label="السعر"
            value={formData.finalPrice}
            onChange={onChange}
            required
            name="finalPrice"
          />

          <Button
            type="submit"
            text="تسجيل"
            isDisabled={!formData.accessoryName}
          />
        </div>
      </div>
    </form>
  );
};

export default AccessoryForm;
