import { useLocation } from "react-router-dom";
import { useContext, useEffect, useState, useRef } from "react";
import { ModalContext } from "../../context";
import Router from "../Router";
import NavBar from "../NavBar";
import Button from "../Button";

const Dashboard = () => {
  const [modal, setModal] = useContext(ModalContext);

  const [isNavBarOpen, setIsNavBarOpen] = useState(true);

  const { pathname } = useLocation();
  const horizontalScrollingDiv = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (horizontalScrollingDiv.current) {
      horizontalScrollingDiv.current.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <div className="flex flex-row w-full min-h-full relative">
      <div
        dir="rtl"
        className={`bg-primary p-4 grow w-full overflow-scroll`}
        ref={horizontalScrollingDiv}
      >
        <Router />
      </div>
      <NavBar setIsNavBarOpen={setIsNavBarOpen} isNavBarOpen={isNavBarOpen} />
      {(modal.isModalOpen && (
        <div className="absolute w-full h-full p-4 bg-primaryBg/80 top-0 left-0 bottom-0 right-0 flex justify-center items-center">
          <div className="w-full lg:w-1/4 h-40 bg-secondaryBg rounded-xl flex justify-center items-center flex-col gap-5 p-2 border border-primaryText/20">
            {modal.message || "Are you sure you want to delete?"}
            <div className="flex gap-5">
              <Button
                small
                text="Cancel"
                action={() => {
                  setModal({
                    isModalOpen: false,
                    modalAction: () => {},
                  });
                }}
              />
              <Button
                text="Confirm"
                action={() => {
                  modal.modalAction();
                  setModal({
                    isModalOpen: false,
                    modalAction: () => {},
                  });
                }}
              />
            </div>
          </div>
        </div>
      )) ||
        null}
    </div>
  );
};

export default Dashboard;
