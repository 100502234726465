import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";
import Header from "../Header";
import Input from "../Input/Input";

const EmployeeForm = () => {
  const navigate = useNavigate();
  const { employeeId } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    joinDate: "",
    hourlyRate: "",
    salaryFrequency: "",
    jobTitle: "",
    shift: "",
    status: 0,
    deleted: 0,
  });

  useEffect(() => {
    if (employeeId) {
      (async () => {
        const response = await axios.post(`${settings.api}employees/view`, {
          employeeId: employeeId,
        });

        if (response.data.data) {
          setFormData(response.data.data[0] || {});
        }
      })();
    }
  }, [employeeId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      dir="rtl"
      onSubmit={async (e) => {
        e.preventDefault();

        const response = await axios.post(
          `${settings.api}employees/${employeeId ? "update" : "create"}`,
          formData,
        );

        if (response.data.statusCode === 200) {
          navigate("/employees");
        }
      }}
    >
      <div className="w-full">
        <Header title="استماره الموظف" />

        <div className="flex flex-col w-full lg:w-1/2 m-auto gap-4">
          <Input
            label="الاسم"
            value={formData.name}
            onChange={onChange}
            required
            name="name"
          />

          <Input
            label="تاريخ التعيين"
            placeholder="yyyy-mm-dd"
            value={formData.joinDate}
            name="joinDate"
            onChange={onChange}
            required
            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
            type="date"
          />

          <Input
            label="سعر الساعه"
            placeholder="150"
            value={formData.hourlyRate}
            name="hourlyRate"
            onChange={onChange}
            required
          />

          <Input
            label="فتره المرتب"
            placeholder="daily, weekly, monthly"
            value={formData.salaryFrequency}
            name="salaryFrequency"
            onChange={onChange}
            required
          />

          <Input
            label="الوظيفه"
            value={formData.jobTitle}
            name="jobTitle"
            onChange={onChange}
            required
          />

          <Input
            label="عدد ساعات العمل"
            value={formData.shift}
            name="shift"
            onChange={onChange}
            required
          />

          <Button
            type="submit"
            text="تسجيل"
            isDisabled={
              !(
                formData.name &&
                formData.joinDate &&
                formData.hourlyRate &&
                formData.salaryFrequency &&
                formData.jobTitle &&
                formData.shift
              )
            }
          />
        </div>
      </div>
    </form>
  );
};

export default EmployeeForm;
