import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Header from "../Header";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ModalContext } from "../../context";
import settings from "../../config";
import { format } from "../../utils";

const Assets = () => {
  const [assets, seAssets] = useState([]);
  const [admin] = useLocalStorage("admin");
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}assets/`);

      if (response.data.statusCode === 200) {
        seAssets(response.data.data);
      }
    })();
  }, []);

  const deleteBillCategory = async (assetId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(`${settings.api}assets/delete`, {
          assetId,
        });

        if (response.data.statusCode === 200) {
          seAssets(assets.filter((asset) => assetId !== asset.assetId));
        }
      },
    });
  };

  let total = 0;

  return (
    <div className="w-full" dir="rtl">
      <Header title="الاصول" text="اضافه اصل" link="/asset/form" />
      <table className="w-full table-auto text-right">
        <thead>
          <tr>
            <th className="p-4">مسلسل</th>
            <th className="p-4">الاصل</th>
            <th className="p-4">العدد الاجمالى</th>
            <th className="p-4">السعر الاجمالى</th>
            <th className="text-center w-24 p-4">التفاصيل</th>
            {admin.canEdit === 1 && (
              <th className="text-center w-24 p-4">تعديل</th>
            )}
            {admin.canDelete === 1 && (
              <th className="text-center w-24 p-4">حذف</th>
            )}
          </tr>
        </thead>
        <tbody>
          {assets.map((asset) => {
            total += asset.price || 0;

            return (
              <tr key={asset.assetId} className="odd:bg-secondaryBg">
                <td className="p-4">{asset.assetId}</td>
                <td className="p-4">{asset.assetName}</td>
                <td className="p-4">{asset.quantity || 0}</td>
                <td className="p-4">{asset.price || 0}</td>
                <td className="text-center p-4">
                  <Link
                    to={`/asset/details/${asset.assetId}`}
                    className="flex flex-col items-center w-full cursor-pointer"
                  >
                    📄
                  </Link>
                </td>
                {admin.canEdit === 1 && (
                  <td className="text-center p-4">
                    <Link
                      to={`/asset/form/${asset.assetId}`}
                      className="flex flex-col items-center w-full cursor-pointer"
                    >
                      ✏
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className="text-center p-4">
                    <div
                      className="flex flex-col items-center w-full cursor-pointer"
                      onClick={() => deleteBillCategory(asset.assetId)}
                    >
                      ❌
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
          <tr>
            <td colSpan={3} className="text-rose-500 p-4">
              اجمالى المتبقى
            </td>
            <td className="text-rose-500 p-4">{format(total)}</td>
            <td colSpan={3}></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Assets;
